@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100&family=Nunito+Sans:opsz,wght@6..12,200&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fraternité:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&display=swap');

body {
  font-family: Nunito Sans !important;
  height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  background: url(./Static/Image/background.webp);
  #root {
    // height: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
   
    * {
      font-family: Nunito Sans;
      outline: none;
      box-sizing: border-box;
    }
  }
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
}
