.termsSection{
    max-width: 1440px;
    margin: auto;
}

.tandcfstsec{
    width: 100%;
    overflow: hidden;
    height: 100vh;
    background-image: url("../../../public/assets/images/about-us-top.png");
    background-repeat: no-repeat, no-repeat;
    background-position: right center, left top;
    background-size: 50% auto, 100% 100%;
}

.tandcfstsec h1{
    font-family: "Fraternite" !important;
    color: #5a1740;
    font-weight: 400;
    font-style: normal;
    font-size: 80px;
    line-height: 86px;
    padding: 0 0 15px 0;
    max-width: 650px;
}

.tandcsndsec{
    width: 100%;
    overflow: hidden;
    padding: 50px 0 80px 0;
}

.tandcsndsec p{
    margin-bottom: 20px !important;
    font-size: 1.05rem;
    line-height: 1.6;
    color: #262525;
    font-family: "Playfair Display", serif !important;
    strong{
        font-family: "Playfair Display", serif !important;
    }
    em{
        font-family: "Playfair Display", serif !important;
    }
}

.tandcsndsec ul li{
    font-size: 1.05rem;
    line-height: 1.6;
    list-style-position: inside;
    list-style-type: disc;
    color: #262525;
    font-family: "Playfair Display", serif !important;
}

.herosection-text {
    position: relative;
    top: 30%;
    transform: translateY(-50%);
}

@media screen and (max-width: 1400px) {
    .tandcfstsec{
        height:80vh;  //adjusted the height to look image properly
    }
    
}
@media screen and (max-width: 767px) {
    .tandcfstsec{
        background-position: center 20px, left top;
        background-size: 100% auto, 100% 100%;
        padding-top: 90%;
        padding-bottom: 60px;
        height: 70vh;
    }
    .tandcfstsec h1{
        max-width: 100%;
        font-size: 34px !important;
        line-height: 38px !important;
        text-align: center;
        padding: 50px 0 15px 0;
        padding: 50px 0 15px 0;
    }
}

@media screen and (max-width: 991px) {
    .tandcfstsec{
        height: 60vh;                      //adjusted the heading alingment
    }
    .tandcfstsec .herosection-text {
        top: 40%;
    }
    .tandcfstsec{
        height: 60vh;                      //adjusted the heading alingment
    }
    .tandcfstsec .herosection-text {
        top: 40%;
    }
    .tandcfstsec h1 {
        font-size: 45px;
        line-height: 50px;
    }
}

@media screen and (max-width: 1600px) {
    .tandcfstsec h1 {
        font-size: 65px;
        line-height: 65px;
        max-width: 535px;
    }
}