.abtpgfstcontsec {
  width: 100%;
  overflow: hidden;

  .abtpgimagecontent {  //adjusted the about the page styles
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;

    .text-content {
      flex: 1;
      max-width: 580px;
    }

    .about-image {
      flex: 1;
      max-width: 50%;
      height: auto;
      object-fit: contain;
    }

    h1 {
      font-family: "Fraternite", serif !important;
      color: #5a1740;
      font-weight: 400;
      font-size: 65px;
      line-height: 65px;
      padding: 0 0 15px 0;
    }

    p {
      color: #262525;
      font-weight: 400;
      font-size: 16px;
      line-height: 32px;
      font-family: "Playfair Display", serif !important;
    }
  }
  @media screen and (max-width: 991px) {   //adjusted the image according to the resolutions
    .abtpgimagecontent {
      gap: 0px;
    }

    .herosection-text {
      flex-direction: column;

      .text-content,
      .about-image {
        max-width: 100%;
      }

      h1 {
        font-size: 34px;
        line-height: 38px;
      }
    }
  }
}

.abtpgsndcontsec h2 {
  font-family: "Fraternite", serif !important;
  color: #5a1740;
  font-weight: normal;
  font-style: normal;
  font-size: 80px;
  line-height: 86px;
}
.abtpgsndcontsec {
  padding: 0 0 100px 0;
  width: 100%;
  overflow: hidden;
  margin-top: 40px;
}

.midabtheight {
  height: 500px;
}
.missionvisiondatasec {
  width: 100%;
  background: #ffffff;
  border: 1px solid #cfcfcf;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  padding: 30px 30px 60px 30px;
  position: relative;
}

.missionvisiondatasec:before {
  bottom: 31px;
}

.missionvisiondatasec:before {
  content: "";
  position: absolute;
  left: -18px;
  bottom: 50px;
  z-index: 1;
  background: url("../../../public/assets/images/star.png") no-repeat left top;
  width: 37px;
  height: 37px;
}
.missionvisiondatasec p {
  font-family: "Playfair Display", serif !important;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
}
.missionvisiondatasec h3 {
  font-family: "Playfair Display", serif !important;
  font-weight: 500;
  font-style: normal;
  line-height: 36px;
  text-transform: capitalize;
  color: #df7900;
  padding: 0 0 25px 0;
  font-size: 36px;
}

.abtpgthrdcontsec {
  width: 100%;
  overflow: hidden;
  background: url("../../../public/assets/images/sanathana-darma.png") no-repeat
    center 34%;
  background-size: 50% auto;
  padding-bottom: 75px;
}

.abtpgthrdcontsec h2 {
  text-align: center;
  font-family: "Fraternite", serif !important;
  color: #5a1740;
  font-weight: normal;
  font-style: normal;
  font-size: 80px;
  line-height: 90px;
  padding: 0 0 25px 0;
}

.abtpgthrdcontsec p {
  font-family: "Playfair Display", serif !important;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 32px;
  color: #262525;
}

.aboutUsSection {
  max-width: 1440px;
  margin: auto;
}

.abtpgsndcontsec .col-lg-6:nth-child(2) {
  margin-top: 80px;
}

@media screen and (min-width: 768px) and (max-width: 1140px) {
  .abtpgfstcontsec {
    background-position: right top 190px;
  }
  .abtpgfstcontsec p {
    color: #262525;
    font-style: normal;
    font-size: 16px;
    line-height: 32px;
    max-width: 580px;
    font-family: "Playfair Display", serif !important;
  }
  .missionvisiondatasec h3 {
    font-size: 36px;
    line-height: 36px;
  }
  .abtpgsndcontsec h2 {
    font-family: "Fraternite", serif;
    color: #5a1740;
    font-weight: 500;
    font-style: normal;
    font-size: 65px;
    line-height: 54px;
    margin-bottom: 2rem;
    margin-top: 0rem;
  }

  .abtpgthrdcontsec h2 {
    text-align: center;
    font-family: "Fraternite", serif !important;
    color: #5a1740;
    font-style: normal;
    font-size: 65px;
    line-height: 52px;
  }
  .abtpgthrdcontsec p {
    line-height: 28px;
    font-weight: 400;
    font-size: 16px;
  }
  .aboutUsSection {
    max-width: 1440px;
    margin: auto;
    padding-right: 7%;
    padding-left: 7%;
  }
  .abtpgfstcontsec h1 {
    font-size: 49px;
    line-height: 63px;
  }
}
@media screen and (max-width: 2560px) and (min-width: 1921px) {
  .herosection-text .container {
    max-width: 1700px;
  }
  .abtpgsndcontsec .container,
  .abtpgthrdcontsec .container {
    max-width: 1700px;
  }
}
@media screen and (max-width: 1600px) {
  .abtpgfstcontsec h1 {
    font-size: 65px;
    line-height: 65px;
    max-width: 535px;
  }
  .missionvisiondatasec h3 {
    font-size: 36px;
    line-height: 36px;
  }
  .missionvisiondatasec:before {
    bottom: 31px;
  }
  .abtpgsndcontsec .col-lg-6:nth-child(2) .missionvisiondatasec:before {
    bottom: 50px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1366px) {
  .missionvisiondatasec:before {
    bottom: 57px;
  }
  .abtpgsndcontsec h2 {
    font-family: "Fraternite", serif !important;
    color: #5a1740;
    font-weight: 500;
    font-style: normal;
    font-size: 65px;
    line-height: 61px;
    margin-top: 0rem;
    margin-bottom: 30px;
  }
  .missionvisiondatasec:before {
    bottom: 31px;
  }
  .abtpgthrdcontsec h2 {
    font-family: "Fraternite", serif !important;
    color: #5a1740;
    font-style: normal;
    font-size: 65px;
    align-content: center;
    text-align: center;
  }
}
@media screen and (max-width: 1024px) {
  .abtpgthrdcontsec {
    background-size: 80% auto;
    background-position: center 33%;
  }
}
@media screen and (max-width: 991px) {
  .abtpgimagecontent {
    flex-direction: column;
    
    .about-image {
      order: -1; // Makes image appear first
      max-width: 100%;
      margin-bottom: 20px;
    }
    
    .text-content {
      max-width: 100%;
    }
  }
  .abtpgthrdcontsec {
    background-position: center 35%;
    background-size: 90% auto;
  }
  .contactcontsec h1 {
    font-size: 40px;
  }
  .contactdatasec h2 {
    padding: 0;
  }
  .contactformsec {
    margin-top: 40px;
  }
  .contactdatasec {
    padding-top: 0;
  }
  .ritual-head-line .col-lg-2 {
    width: 25%;
  }
  .ritual-head-line .col-lg-2:nth-child(3) {
    width: 30%;
  }
  .ritual-head-line .col-lg-6 {
    width: 20%;
  }
  .abtpgsndcontsec {
    background-image: none;
    h2{
      text-align: center;
    }
  }
  .missionvisiondatasec {
    padding: 50px;
  }
  .missionvisiondatasec:before {
    bottom: 35px;
  }
  .abtpgsndcontsec .col-lg-6:nth-child(2) .missionvisiondatasec:before {
    bottom: 35px;
  }
}
@media screen and (max-width: 767px) {
  .abtpgthrdcontsec {
    background-position: center 38%;
  }
  .abtpgfstcontsec h1 {
    max-width: 100%;
    font-size: 34px;
    line-height: 38px;
    margin-top: 0 !important;
  }
  .missionvisiondatasec {
    padding: 40px;
  }
  .missionvisiondatasec h3 {
    font-size: 20px !important;
    line-height: 24px !important;
  }
}

@media screen and (max-width: 360px) {
  .text-content h1 {
    max-width: 100%;
    font-size: 34px !important;
    line-height: 38px;
    margin-top: 40px !important;
    text-align: center;  //removed the duplicates
  }
  .missionvisiondatasec {
    padding: 30px !important;
    margin-top: 10px;
  }
  .abtpgthrdcontsec h2 {
    font-size: 34px !important;
    line-height: 36px !important;
  }
  .abtpgthrdcontsec {
    background-size: 120% auto;
    background-position: center 39%;
  }
  .midabtheight {
    height: 235px;
  }
  .abtpgsndcontsec {
    margin-top: 30px;
  }
  .abtpgsndcontsec .col-lg-6:nth-child(2) {
    margin-top: 20px;
  }
}
@media screen and (min-width: 361px) and (max-width: 768px) {
  .about-image {
    flex: 1;
    max-width: 100% !important;
    height: auto;
    object-fit: contain;
  }
  .text-content h1 {
    max-width: 100%;
    font-size: 45px !important;
    line-height: 35px !important;
    text-align:center;
  }
  .abtpgsndcontsec h2,
  .abtpgthrdcontsec h2,
  .abtpgfrthcontsec h2 {
    font-size: 45px;
    line-height: normal;
  }
  .missionvisiondatasec h3 {
    font-size: 20px;
    line-height: 24px;
  }
  .abtpgsndcontsec .col-lg-6:nth-child(2) {
    margin-top: 30px;
  }
  .abtpgsndcontsec {
    padding-bottom: 70px;
  }
  .abtpgthrdcontsec {
    background-position: center 38%;
  }
  .midabtheight {
    height: 310px;
    margin-bottom: 70px;
  }
  .abtpgfrthcontsec {
    padding: 60px 0;
  }
  .abtpgfrthsecbtmsec {
    margin-top: 50px;
  }
  .abtpgfrthcontsec h2 {
    padding-bottom: 0;
    margin-bottom: 20px;
  }
  .missionvisiondatasec {
    padding: 40px;
  }
}
@media screen and (max-width: 479px) {
  .text-content h1 {
    max-width: 100%;
    font-size: 34px !important;
    line-height: 35px !important;
    text-align:center;
  }
  .about-image {
    flex: 1;
    max-width: 100% !important;
    height: auto;
    object-fit: contain;
  }
  .abtpgsndcontsec h2,
  .abtpgthrdcontsec h2,
  .abtpgfrthcontsec h2 {
    font-size: 34px;
    line-height: 36px;
  }
  .missionvisiondatasec {
    padding: 20px;
    margin: 10px;
  }
  .abtpgthrdcontsec {
    background-size: 100% auto;
    background-position: center 39%;
  }
  .midabtheight {
    height: 235px;
  }
}
