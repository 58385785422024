.newofferingsec {
  width: 100%;
  padding: 50px;    //adjusted the padding
  h1 {
    text-align: center;
    color: #62324e;
    font-size: 72px !important;
    font-weight: normal;
    padding-bottom: 20px;
    font-family: "Playfair Display", serif !important;
  }
}
.accordion-item {
  margin-bottom: 40px !important;
  margin-left: auto;
  margin-right: auto;
}
.accordion-item {
  background: #fff;
  border: none;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  margin-bottom: 20px;
}
.accordion-button {
  color: #62324e;
  font-size: 32px;
  font-weight: 500;
  font-family: "Fraternite";
  background-color: #fff;
  border: none !important;
  border-color: #fff;
  line-height: normal;
  padding-left: 10px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.accordion-button::after {
  background-image: none !important;
}
.my-booking-pujaname img {
  margin-right: 10px;
  margin-top: 7px;
  margin-left: 20px;
  cursor: pointer;
}
img {
  max-width: 100%;
  height: auto;
}
.booking-puja-name {
  white-space: normal;
  line-height: 40px;
}
.collapse-booking-details {
  margin-left: auto !important;
}
.booking-details-box h6 span img {
  max-width: 16px;
  max-height: 16px;
  margin-right: 5px;
}
.mybooking-top-details,
.accordion-body {
  padding: 15px 36px;
  padding-bottom: 0 !important;
}
.booking-puja-name {
  font-family: "Fraternite" !important;
  padding: 0px 0 !important;
  font-size: 40px;
  line-height: 67px;
  font-weight: 500;
  font-family: "Fraternite";
  background-color: #fff;
  border: none !important;
  border-color: #fff;
  margin-bottom: 0px;
}
.booking-details-box p {
  padding-left: 22px;
  font-size: 14px;
  font-family: "Quicksand" !important;
}
.calender-time-wrap {
  display: flex;
  align-items: center;
  gap: 10px;
}
.booking-details-box {
  font-family: "Quicksand" !important;
  i {
    font-size: 13px;
    color: #262525;
  }
}
.booking-details-box h6 {
  font-size: 15px;
  font-family: "Quicksand" !important;
}
.title-wrapper {
  display: flex;
  align-items: center;
}
.header-wrapper {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  width: 100%;
}
.mybookings-status h2 {
  font-weight: 400;
  font-size: 32px;
  line-height: 31px;
  color: #62324e;
  margin-bottom: 32px;
  padding: 1rem 2rem 0;
  font-family: "Nunito Sans" !important;
}
.mybookings-status-wrapper {
  display: flex;
  align-items: center;
  text-align: center;
}
.mybookings-satus-box {
  width: 20%;
  position: relative;
  z-index: 1;
}
.mybooking-satus-icon-wrap.completed {
  background: #df7900;
  border-color: #df7900;
}
.mybooking-satus-icon-wrap {
  border: 1px solid #f2c999;
  border-radius: 100px;
  padding: 5px;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  background: #fff;
}
.mybookings-satus-box img {
  max-width: 100%;
  height: auto;
}
.mybookings-satus-box p {
  height: 3rem;
  font-family: "Quicksand";
}
.mybookings-satus-box::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: #f2c999;
  top: 25px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.mybookings-satus-box:last-child:after {
  left: 0%;
}
.mybookings-satus-box:first-child:after {
  left: 100%;
}
.mybookings-status h2 {
  font-weight: 600;
  font-size: 30px;
  line-height: 31px;
  color: #62324e;
  margin-bottom: 32px;
  padding: 1rem 2rem 0;
  font-family: "Nunito Sans";
}
.samagri-popup-heading-container h2 {
  margin-bottom: 30px;
  font-size: 35px;
}
.samagri-popup-outer {
  display: flex;
  justify-content: flex-start;
}
.samagri-popup-container {
  background-color: #fff;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #262525;
  margin: 10px 0 50px;
  width: 100%;
}
.my-booking-samagri {
  padding: 0 0 0 30px;
}
.samagri-popup-heading-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.samagri-list {
  margin-top: 20px;
}
.my-bookings-invoice {
  padding: 0 20px 20px;
  margin: 15px 30px 50px 30px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  background: #ffffff;
  border: 1px solid #cfcfcf;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  font-size: 14px;
}
.my-bookings-invoice .price-box {
  padding-right: 0;
}
.my-bookings-invoice .price-box div {
  font-family: "Quicksand" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #262525;
}
.price-box p {
  margin: 0px;
  font-family: "Quicksand" !important;
  font-size: 13px;
  font-weight: 500;
}
.floater-booked-price {
  color: #df7900;
  font-weight: 600;
  font-size: 16px !important;
}
.samagri-heading {
  display: inline;
  font-family: "Quicksand" !important;
  font-size: 20px;
  font-weight: 600;
  color: #5a1740;
}
.samagri-price-heading {
  font-size: 17px !important;
}
.price-box .total {
  font-family: "Playfair Display" !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px;
  color: #262525;
}
.totalSubDetail {
  margin-top: 10px;
  font-size: 11px !important;
  font-family: "Quicksand" !important;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: #5a1740;
}
.accordion-item .right-side-floater {
  display: none;
  margin-top: 70px;
}
.procedure-list-container {
  margin: 0 0 0 30px;
  margin-top: 30px;
}
.samagri-also-needed {
  background-color: rgb(209, 162, 225, 0.3);
  border-width: 1.5px;
  border-style: solid;
  border-image: linear-gradient(
      to right,
      #9a7013,
      #d6b677,
      #9e730f,
      #ffe083,
      #c39118,
      #e2b85c
    )
    1;
  padding: 20px;
}
.invoice-payment {
  display: flex;
  font-size: 17px;
  font-weight: 600;
  justify-content: center;
  font-family: "Quicksand" !important;
}
.bookbut.disabled {
  background-color: #f6f0f0;
  cursor: not-allowed;
  opacity: 0.1;
}

.int-fill {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border: 1px solid #d8d8d8;
}

.radio-yes,
.radio-no {
  width: auto;
  margin-right: 10px;
}
.invoice-box {
  padding: 20px;
}
.note {
  font-weight: 600;
  font-size: 10px;
  padding: 8px;
  margin-bottom: 0;
}
.error-message {
  color: red;
  font-size: 12px;
  font-weight: 600;
  margin-left: 10px;
}
.remaining {
  padding: 15px 0px;
  font-weight: 600;
  color: red;
  font-size: 15px;
}
.booking-special-notes {
  padding: 30px 30px 0;
}
.booking-special-notes h2 {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 36px;
  color: #5a1740;
  margin-bottom: 15px;
}
.booking-special-notes p {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #262525;
}
.samagri-list .samagri-item-name {
  display: -webkit-inline-box;
  width: 350px;
}
.details-btn {
  margin-left: 10px;
}

.details-book-now .bookbut{
  padding: 0px !important;
}
@media screen and (min-width: 991px) and (max-width: 1140px) {
  .samagri-list .samagri-item-name {
    display: -webkit-box !important;
    width: 270px;
  }
}
@media screen and (max-width: 991px) {
  .accordion-item .right-side-floater {
    width: 100%;
    position: unset;
    float: unset;
    margin-top: 30px;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333% !important;
  }
  .samagri-list {
    columns: 1;
  }
  .my-booking-samagri {
    padding: 0 20px;
  }
  .procedure-list-container {
    margin: 0 0 0 20px;
    margin-top: 30px;
    margin-right: 20px;
  }
  .samagri-what-you-need-items ul {
    columns: 1;
    -webkit-columns: 1;
  }
  .samagri-what-you-need-items img {
    margin-right: 10px;
  }
}
@media screen and (max-width: 479px) {    //fixed align for mobile screen
  .see-details-btn {
    flex-direction: column;
  }
  .details-btn {
    margin-bottom: 10px;
  }
  .mybooking-sec h1 {
    padding-bottom: 20px;
    font-size: 40px !important;
    text-align: center;
  }
  .my-booking-container {
    padding: 0;
  }
  .booking-puja-name {
    font-size: 30px !important;
    white-space: normal;
    line-height: 30px;
  }
  .my-booking-pujaname img {
    margin: 0px;
  }
  .accordion-button {
    padding: 0px !important;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 100% !important;
  }
  .mybookings-status h2 {
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .samagri-heading {
    font-size: 18px;
  }

  .accordion-item {
    margin-left: 20px;
  }
  .mybookings-status-wrapper .mybooking-satus-icon-wrap {
    margin: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 15px 0px 0px;
  }
  .mybookings-status-wrapper {
    display: block;
  }
  .mybookings-status-wrapper .mybookings-satus-box {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }
  .mybookings-status-wrapper .mybookings-satus-box p {
    height: fit-content;
    text-align: left;
    width: 75%;
    margin-bottom: 0;
  }
  .mybookings-status-wrapper .mybookings-satus-box::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    background: #f2c999;
    top: 25px;
    left: 25px;
    transform: translate(0%, 0%);
    z-index: -1;
  }
  .mybookings-satus-box:last-child:after {
    display: none;
  }
  .samagri-list li .samagri-item-name {
    width: 200px;
    display: -webkit-inline-box;
    font-family: "Quicksand" !important;
    font-size: 14px;
  }
  .samagri-list li .samagri-item-quantity {
    width: 75px;
  }
  .samagri-what-you-need-items .samagri-item-name {
    font-family: "Quicksand" !important;
    font-size: 14px;
  }
}
