
a.hamburger {
  float: right;
}

a, a:hover, a:focus {
  text-decoration: none;
  border: 0;
  outline: 0;
  color: #262525 !important;
}
a.ritualbut {
  display: block;
  border: 1px solid #5a1740;
  line-height: 40px;
  padding: 0 36px;
  text-transform: capitalize;
  color: #fff !important;
  font-family: "Quicksand", sans-serif !important;
  font-weight: 500;
  float: right;
  margin-right: 20px;
  background: #5a1740;
  text-decoration: none;
}

.widget-content img{
  width: auto !important;
}
.navsec {
  float: right;
  
}

ul, li, ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.navsec ul li.phone {
  font-family: "Quicksand", sans-serif;
  border-right: 0.5px solid rgba(38, 37, 37, 0.5);
  margin-right: 20px;
  font-weight: 500;
  display: none;
}

.navsec ul li {
  float: left;
  line-height: 42px;
  font-weight: 500;
  font-size: 16px;
  color: #262525;
}

.navsec ul li.phone a {
  padding-right: 20px;
  background: url(../../../public/assets/images/headerline.png) no-repeat left center;
  padding-left: 25px;
}

.navsec ul li a {
  color: #262525;
  padding: 0 30px 0 0;
}

.headline {
  line-height: 40px;
  padding: 6px 30px 0 0;
  margin: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.headline img {
  width: 80%;
  margin-right: 25px;
  height: 1.5px;
  margin-top: 8px;
}
.headline a {
  font-family: "Playfair Display", serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #262525 !important;
  margin-top: 4px;
  white-space: nowrap;
  text-decoration: none;
}
.headline span{
  font-family: "Playfair Display", serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #262525 !important;
  margin-top: 4px;
  white-space: nowrap;
  text-decoration: none;
  margin-right: 10px;
}
.headline div{
  display: none !important;
}
.our-booking-url{
  margin-left: 10px;
}
@media screen and (min-width: 1025px) and (max-width: 1920px) {
  .headersec {
      position: relative;
  }
}

.headersec {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  padding: 20px 20px 0 30px;
}

@media screen and (max-width: 1600px) {
  .headersec .row .col-lg-2 {
      padding: unset;
  }
}
@media screen and (max-width: 768px) {
  .headline div{  //display the div only on mobile
    display: block !important;
  }
  .headline span{
    display: none;
  }
   .our-offering-url{
    display: none;
   }
   .our-booking-url{
    display: none;
   }
  .headline{
    padding: 0px 10px 0 0;
  }
  .textwidget{
    display: block;
  }
  a .ritualbut{
    line-height: 30px;
  }
}
/**
 * Media queries for adjusting the width of the image in the navbar based on screen size.
 * 
 * @media screen and (min-width: 768px) and (max-width: 863px) - Adjusts the width of the image to 20%.
 * @media screen and (min-width: 863px) and (max-width: 990px) - Adjusts the width of the image to 30%.
 * @media screen and (min-width: 990px) and (max-width: 1200px) - Adjusts the width of the image to 50%.
 */
@media screen and (min-width: 863px) and (max-width:990px) {
  .headline img {
    width: 30%;
  }
}
@media screen and (min-width: 990px) and (max-width:1200px) {
  .headline img {
    width: 50%;
  }
}
@media screen and (max-width: 460px) {
.headersec {
  padding: 10px 0 0 10px;
}
.headline div{
  display: block !important;
}
.headline span{
  display: none;
}
.headline img{
margin-right: 15px;
margin-top: 0px;
}
a.ritualbut{
  display: none;
}
}

@media screen and (min-width:360px) and (max-width:768px) {
  a.ritualbut{
    display: none;
  }
}