.loader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
    
    &.fullscreen {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 9999;
      min-height: 100vh;
    }
  
    .loader-container {
      text-align: center;
      
      .loader {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
        
        .loader-image {
          width: 100%;
          height: 100%;
          animation: pulse 1.5s ease-in-out infinite;
        }
      }
    }
  }
  
  @keyframes pulse {
    0% { transform: scale(0.95); opacity: 0.5; }
    50% { transform: scale(1.05); opacity: 1; }
    100% { transform: scale(0.95); opacity: 0.5; }
  }