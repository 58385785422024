.footersec {
  width: 100%;
  overflow: hidden;  //fixed the extra space bug
  background-size: 40% auto;
}
.topftrsec span {
  padding-left: 20px;
  text-align: center;
  width: 100%;
  margin: auto;
}
.topftrsec .container a.bookbut {
  text-align: center;
  width: 160px;
  display: flex;
  align-items: center;
  height:42px !important;
} //alignment issues
.topftrsec .container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.topftrsec {
  width: 100%;
  overflow: hidden;
  margin: auto;
  max-width: 1440px;
}

a.bookbut {
  background: #5a1740;
  line-height: 40px;  //adjusted the alignemnts
  color: #fff;
  font-weight: 500;
  font-family: "Quicksand", sans-serif;
  display: block;
  float: left;
  border: 1px solid #5a1740;
}
.topftrsec .containers a.bookbut {
  display: flex;
  justify-content: center;
  width: 160px;
}

.ftrlinks {
  width: 100%;
  overflow: hidden;
  border-right: 0.5px solid rgba(0, 0, 0, 0.5);
}
.ftrlinks ul li {
  font-size: 16px;
  line-height: normal;
  text-transform: capitalize;
  font-weight: 500;
  width: 50%;
  float: left;
  margin: 18px 0 0 0;
}
.ftrlinks ul li a {
  color: #262525 !important;
  font-family: "Playfair Display", serif !important;
  &:hover {
    transform: scale(1.02);
  }
}
.ftrlinks {
  width: 100%;
  overflow: hidden;
  border-right: 0.5px solid rgba(0, 0, 0, 0.5);
}
.midftrsec h2 {
  font-size: 14px;
  line-height: normal;
  font-family: "Quicksand", sans-serif !important;
  color: #262525;
  text-transform: capitalize;
}
.sociallinksec {
  width: 100%;
  overflow: hidden;
  margin-top: 20px;
}
.sociallinksec ul {
  float: left;
}
.sociallinksec p {
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #262525;
  float: left;
  padding: 0 10px 0 0;
  font-family: "Playfair Display", serif !important;
}
.ftrlinks ul li {
  font-size: 16px;
  line-height: normal;
  text-transform: capitalize;
  font-weight: 500;
  width: 50%;
  float: left;
  margin: 18px 0 0 0;
}
.midftrsec {
  width: 100%;
  overflow: hidden;
  padding: 30px 0;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-left: 0 !important;
}
.botftrsec {
  width: 100%;
  overflow: hidden;
  background: #5a1740;
}
.widget_text {
  margin: auto;
  max-width: 1440px;
}
.bookbut{      //changed the button style on hover 
  background-color: #5A1740;
}
.bookbut:hover {
  background-color: #761E54;
}
.textwidget {
  margin: auto;
  max-width: 1440px;
}
.botftrsec p {
  color: #ffffff;
  line-height: normal;
  font-weight: 600;
  margin-bottom: 0;
  font-family: "Playfair Display", serif !important;
  padding-top: 24px;
  padding-bottom: 24px;
  font-size: 18px;
}
.sociallinksec ul li a {
  color: #5a1740 !important;
}
.sociallinksec ul li {
  float: left;
  margin: 0 0 0 18px;
  font-size: 24px;
  line-height: normal;
}

.footersec a {
  text-decoration: none;
}

.footersec .textwidget {
  color: #fff !important;
}

@media screen and (max-width: 1350px) {
  .midftrsec h2 {
    font-size: 17px;
  }
  .ftrlinks ul li {
    font-size: 10px;
  }
  .sociallinksec p {
    font-weight: 700;
    font-size: 18px;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1920px) {
  .footersec {
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .ftrlinks {
    margin-top: 2rem;
  }
  .topftrsec .containers a.bookbut {
    white-space: nowrap;
  }
  .botftrsec p {
    font-size: 15px;
  }
  .textwidget {
    padding-left: 5%;
    padding-right: 5%;
  }
}
@media screen and (max-width: 1600px) {
  .topftrsec h2 {
    font-size: 32px;
  }
  .botftrsec p {
    font-size: 15px;
  }
}
@media screen and (max-width: 1399px) {
  .topftrsec span {
    width: 85%;
  }
  .ftrlinks ul li {
    font-size: 13px;
  }
  .sociallinksec ul li {
    margin: 0 12px 0 0;
  }
}
@media screen and (max-width: 1024px) {
  .footersec {
    background-position: right bottom;
    background-size: 40% auto;
  }
  .ftrlinks ul li {
    float: none;
    width: 100%;
    margin: 12px 0 0 0;
  }
}
@media screen and (max-width: 991px) {
  .topftrsec h2 {
    font-size: 28px;
  }
  .topftrsec span {
    width: 80%;
  }
  .sociallinksec p {
    float: none;
    padding: 0;
    line-height: normal;
    padding-bottom: 10px;
  }
  .footersec .topftrsec .container a.bookbut {
    width: 140px;         //fixed the button width
  }
}
@media screen and (max-width: 768px) {
  .footersec .topftrsec .container a.bookbut {
    width: 140px !important;
  }
  .widget_text .widget-content {
    display: flex;
    align-items: center;
  }
  .topftrsec span {
    width: 70%;
  }
  .midftrsec .col-md-4 {
    width: 100% !important;
  }
  .topftrsec h2 {
    font-size: 25px;
    float: none;
    padding: 0 0 20px 0;
    line-height: normal;
    position: relative;
  }
  .topftrsec h2:after {
    width: 88px;
    height: 2px;
    content: "";
    position: absolute;
    right: 0;
    top: 12px;
    z-index: 1;
  }
  .midftrsec {
    padding: 25px 0;
  }
  .midftrsec h2 {
    font-size: 16px;
  }
  .midftrsec {
    padding: 25px 0;
  }
  .midftrsec h2 {
    font-size: 16px;
  }
  .ftrlinks {
    margin-bottom: 35px;
    border: 0;
  }
  .ftrlinks ul li {
    margin: 15px 0 0 0 !important;
    width: 50%;
    float: left;
  }
  .midftrsec ul li a {
    padding: 0;
  }
  .botftrsec {
    text-align: center;
  }
  .botftrsec p {
    font-size: 12px !important;
  }
  .ftrlinklastcol {
    border-top: 0.5px solid rgba(0, 0, 0, 0.5);
  }
  .footersec {
    background-size: 70% auto;
  }
  .sociallinksec p {
    float: left;
    line-height: 30px;
    padding: 0 20px 0 0;
  }
  .textwidget {
    padding: 0px;
  }
}
@media screen and (max-width: 479px) {
  .textwidget{
  padding:10px 0px;
  }
  .topftrsec h2 {
    font-size: 23px;
  }
  .topftrsec h2:after {
    width: 68px;
  }
  a.bookbut {
    display: inline-block;
    padding: 0 30px;
  }
  .topftrsec span {
    width: 60%;
  }
  .footersec {
    background-size: 100% auto;
    padding: 0;
  }
}
@media screen and (max-width: 374px) {
  .ftrlinks {
    margin-bottom: 25px;
  }
  .topftrsec span {
    width: 52%;
  }
  .textwidget {
    padding: 0px;
  }
  .bookbut {
    align-items: center;
  }
}
