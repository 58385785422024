@tailwind base;
@tailwind components;
@tailwind utilities;

li:focus-visible {
  outline: none;
}
li:hover {
  cursor: pointer;
}
@layer base {
  :root {
    /*------Color variables------*/
    --amber_200: #ffe083;
    --black_900: #000000;
    --black_900_14: #00000014;
    --black_900_19: #00000019;
    --black_900_26: #00000026;
    --black_900_33: #00000033;
    --black_900_4c: #0000004c;
    --black_900_7f: #0000007f;
    --blue_gray_100: #d9d9d9;
    --blue_gray_100_01: #cfcfcf;
    --gray_500: #a6a6a6;
    --gray_700_4c: #6666664c;
    --gray_800: #4d1636;
    --gray_900: #262525;
    --lime_800: #c39118;
    --lime_900: #9a7013;
    --lime_900_01: #9e730f;
    --orange_200: #d6b677;
    --orange_300: #e2b85c;
    --orange_800: #df7900;
    --orange_800_01: #df7800;
    --pink_800: #a73776;
    --primary_maroon: #5a1740;
    --purple_50: #f1e3f6;
    --white_a700: #ffffff;

    /*------Shadow variables------*/
    --shadow-xs: 0 4px 16px 0 #00000014;
    --shadow-sm: 0 4px 16px 0 #00000026;
    --shadow-md: 0 4px 28px 0 #00000019;
    --shadow-lg: 2px 2px 15px 0 #00000033;

    /*------Border radius variables------*/
    --radius-xs: 1px;
    --radius-sm: 18px;
    --radius-md: 26px;

    /*------Spacing variables------*/
    --space-xs: 2px;
    --space-sm: 4px;
    --space-md: 6px;
    --space-lg: 8px;
    --space-xl: 10px;
    --space-2xl: 12px;
    --space-3xl: 14px;
    --space-4xl: 16px;
    --space-5xl: 18px;
    --space-6xl: 20px;
    --space-7xl: 24px;
    --space-8xl: 26px;
    --space-9xl: 28px;
    --space-10xl: 30px;
    --space-11xl: 40px;
    --space-12xl: 48px;
    --space-13xl: 50px;
    --space-14xl: 52px;
    --space-15xl: 62px;
    --space-16xl: 88px;
  }
}
@layer components {
  /*-------------------------------------
  Container CSS
--------------------------------------*/
  .container-xs {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1208px;
  }
}
