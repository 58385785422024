.contactUsSection {
  max-width: 1440px;
  margin: auto;
}

.cntpgfstcontsec h1 {
  font-family: "Fraternite", serif !important;
  color: #5a1740;
  font-weight: 500;
  font-style: normal;
  font-size: 80px;
  line-height: 86px;
  padding: 0;
}

.cntpgsndcontsec {
  width: 100%;
  overflow: hidden;
  padding: 0 0 100px 0;
}

.cntpgsndcontsec h2 {
  font-family: "Fraternite", serif !important;
  color: #5a1740;
  font-weight: 400;
  font-style: normal;
  font-size: 55px;
  line-height: normal;
  padding: 0 0 20px 0;
}

.labelSection h3 {
  font-family: "Quicksand", sans-serif !important;
  font-size: 1.5rem !important;
  font-weight: 700;
  margin: 0;
}

.sglecontactsec p a {
  color: #000000;
}

.contacttext {
  font-family: "Fraternite", serif !important;
}
.phone-icon {
  width: 50px;
  height: 50px;
}

.labelSection {
  margin-top: 30px;
}
.labelSection p a {
  color: black !important;
  font-family: "Quicksand", serif !important;
}
.labelSection p {
  font-family: "Quicksand", serif !important;
  margin: 0 !important;
  a {
    text-decoration: none;
  }
}

.img {
  vertical-align: middle;
}

.cntpgtrdcontsec {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.16);
  box-shadow: 0px 4px 204px rgba(0, 0, 0, 0.08);
  padding: 35px;
}

.cntpgtrdcontsec h2 {
  font-family: "Fraternite", serif;
  color: #5a1740;
  font-weight: 400;
  font-style: normal;
  font-size: 45px;
  line-height: normal;
  padding: 0 0 10px 0;
}

form {
  display: block;
  margin-top: 0em;
}

.cntpgtrdcontsec .col-lg-6,
.cntpgtrdcontsec .col-lg-12 {
  margin-top: 20px !important;
}

input,
select {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px 15px;
  outline: 0;
  color: #333;
  font-size: 1rem;
  line-height: 50px;
  font-weight: normal;
  font-family: "Quicksand", sans-serif;
  width: 100%;
}

textarea {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px 15px;
  outline: 0;
  color: #333;
  font-size: 1rem;
  line-height: normal;
  height: 150px;
  font-weight: normal;
  font-family: "Quicksand", sans-serif;
  width: 100%;
}

.submit {
  color: #fff;
  text-align: center;
  font-weight: 700;
  background-color: #542e42;
  font-size: 20px;
  width: auto;
  &:hover {
    background-color: #761e54;
  }
  &:active {
    background-color: #4b1436; /* Changes color when clicked */
  }
}

.response {
  border-color: #46b450;
  margin: 2em 0.5em 1em;
  padding: 0.2em 1em;
  border: 2px solid #46b450;
  font-family: "Playfair Display", serif !important;
  font-size: 16px;
}
//contact page stylings adjusted
.cntpgfstcontsec {
  width: 100%;
  overflow: hidden;

  .content-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      font-family: "Fraternite", serif !important;
      color: #5a1740;
      font-weight: 500;
      font-style: normal;
      font-size: 80px;
      line-height: 86px;
      padding: 0;
    }

    .hero-image {
      width: 40%;
      height: auto;
      object-fit: contain;
    }
  }
}

@media screen and (max-width: 1600px) {
  .cntpgfstcontsec h1 {
    font-size: 65px;
    line-height: 70px;
  }

  .cntpgsndcontsec h2 {
    font-size: 55px;
  }

  .labelSection h3 {
    font-size: 1.3rem !important;
    margin-bottom: 8px;
  }

  .cntpgtrdcontsec h2 {
    font-size: 40px;
  }
}

@media screen and (max-width: 479px) {
  .cntpgfstcontsec h1 {
    font-size: 30px;
  }

  .cntpgsndcontsec h2,
  .cntpgtrdcontsec h2 {
    font-size: 28px;
  }

  .labelSection h3 {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 1024px) {
  .cntpgfstcontsec h1 {
    font-size: 50px;
    line-height: normal;
  }
  .cntpgsndcontsec h2,
  .cntpgtrdcontsec h2 {
    font-size: 40px;
  }
  .cntpgfstcontsec {
    background-position: right top 50px; //adusted the background position
  }
}

@media screen and (max-width: 991px) {
  .cntpgfstcontsec h1 {
    font-size: 30px;
  }
  .cntpgsndcontsec h2 {
    padding: 0;
  }
  .cntpgtrdcontsec {
    margin-top: 40px;
  }
}

@media screen and (max-width: 767px) {
  .labelSection h3 {
    font-size: 1.3rem;
  }
  .cntpgsndcontsec h2,
  .cntpgtrdcontsec h2 {
    font-size: 32px;
  }
  .cntpgfstcontsec {
    background-position: right center;
    background-size: 60% auto;
    .content-wrapper {
      h1 {
        font-size: 50px !important;
      }
    }
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .phone-icon {
    width: 35px;
    height: 35px;
  }
}
//adjusted according to the resolutions
@media screen and (max-width: 991px) {
  .cntpgfstcontsec {
    .content-wrapper {
      flex-direction: column;

      h1 {
        font-size: 70px;
        line-height: normal;
      }

      .hero-image {
        width: 60%;
      }
    }
  }
}
