body {
  margin: 0;
  padding: 0;
  font-family: Playfair Display;
}

* {
  box-sizing: border-box;
  line-height: normal;
  font-family: inherit;
  margin: unset;
}

a {
  text-decoration: none;
  /* display: block; */
}

ul {
  margin: 0;
  margin-inline: unset !important;
  padding: 0;
  list-style: none;
}

input {
  background-color: transparent;
  width: 100%;
}

input:focus {
  outline: none;
}

.tab-panel--selected {
  animation-name: tabPanel;
  animation-duration: 300ms;
  animation-timing-function: linear;
}

.black_900_4c_gray_700_4c_border {
  border-image: linear-gradient(90deg, #0000004c, #6666664c) 1;
}

.lime_900_orange_300_border {
  border-image: linear-gradient(1deg, #9a7013, #d6b677, #9e730f, #ffe083, #c39118, #e2b85c) 1;
}

@keyframes tabPanel {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.alice-carousel__dots-item {
  cursor: pointer;
}
