.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
  overflow-x: hidden;
  transition: 0.5s;
  padding: 0;
  margin: 0;
  text-align: center;
}

.menubg {
  background: url(../../../public/assets/images/new-menubg.jpg) no-repeat center
    center white;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: table;
  a {
    cursor: pointer;
  }
}

.menubg button{
  background: none;
  border: none;
  padding: 0;
  cursor: pointer
} 

.sidenav .closebtn {
  position: absolute;
  top: 25px;
  right: 25px;
  width: 32px;
  height: 32px;
}

img {
  max-width: 100%;
  height: auto;
}
.menuvalign {
  display: table-cell;
  vertical-align: middle;
}

ul,
li,
ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.sidenav ul li {
  font-size: 22px;
  font-weight: 500;
  line-height: 44px;
}

.sidenav ul li a {
  padding: 0;
  text-decoration: none;
  color: #5a1740 !important;
  font-family: "Playfair Display", serif !important;
  transition: 0.3s;
  cursor: pointer;
  height: 48px;
}

ul.user-signup-wrapper {
  display: flex;
  justify-content: center;
  font-size: 30px;
  align-items: center;
  margin-top: 20px;
}

.user-signup-wrapper li {
  margin: 0px 20px;
  height: 44px;
  line-height: 44px;
}

.user-signup-wrapper li a {
  border: 2px solid #5a1740;
  width: fit-content;
  color: #5a1740;
  padding: 5px 25px !important;     //fixed button alignment issue
  display: block;
  background-color: transparent;
}

.active {
  display: flex;
}

.user-signup-wrapper li.active a {
  background: #5a1740;
  color: #fff !important;
  border: #761E54
}

.otp_button {
  border: none;
  color: #fff;
  font-size: 17px;
  background-color: #62324e;
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
  padding: 12px 40px 12px 40px;
  margin-top: 20px;
  text-align: center;
}

.logout-karishye{
  align-content: center;
  &:hover {
    background-color: #761E54 !important;
  }
}

.my-profile-btn{
  border: 2px solid #5a1740;
  &:hover {
    border: 2px solid #761E54 !important;
  }
}

@media screen and (max-width: 479px) {   //adjusted the button alignment
  .user-signup-wrapper li a {
    padding: 5px 20px !important;   
  }
  }
