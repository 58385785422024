.PoojaDeatailpage {
  .details-top-img {
    text-align: center;
    max-width: 1440px;
    margin: auto;
  }

  .puja-container {
    background-image: url("../../../public/assets/images/puja-frame.png");
    background-size: 100%;
    padding: 30px;
    background-repeat: no-repeat;
    width: 100%;
  }

  .image-slideshow-container {
    position: relative;
    margin: auto;
  }
  .prev {
    left: 0;
  }
  .next {
    right: 0;
  }
  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    user-select: none;
  }
  .img-dot-container {
    position: relative;
    top: -80px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .change-img-dot.active,
  .change-img-dot:hover {
    background-color: #5a1740;
    border-radius: 12px;
    width: 40px;
  }

  .details-heading h2,
  .details-bottom-sec h2,
  .samagri-container h2,
  .special-notes-sec h2,
  .how-it-works h2,
  .faq h2,
  .something-else-container h2,
  .samagri-popup-heading-container h2 {
    font-family: "Nunito Sans", sans-serif !important;
    color: #5a1740;
    font-weight: 600;
    font-style: normal;
    font-size: 40px;
    line-height: 50px;
    padding-right: 1rem;
  }

  .details-head-sec {
    padding: 0rem 0px;
    position: relative;
    top: -109px;
    max-width: 1300px;
    margin: auto;
  }

  .details-head-lft {
    width: 100%;
    position: relative;
    border: 1px solid #cfcfcf;
    border-right: none;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  }

  .details-book-now .bookbut {
    padding: 0px !important;
  }
  .border-line {
    border: 1px solid #cfcfcf;
    margin-bottom: 20px;
    p {
      margin: 0;
    }
  }
  .puja-features {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    margin-left: -8px;
  }
  .available-box img {
    width: 24px;
  }

  .puja-features > div > p {
    font-size: 16px;
    font-family: "Quicksand", serif !important;
    margin-bottom: 0;
    font-weight: 500;
  }

  .puja-features > div > img {
    height: 60px;
    width: 60px;
  }
  .puja-features > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .heading-wrapper {
    position: relative;
    margin-bottom: 15px;
  }
  .available-modes {
    gap: 35px;
    align-items: center !important;
    display: flex;
    padding-bottom: 0 !important;
    margin-bottom: 20px;
  }
  .details-heading {
    background-color: #fff;
    padding: 35px;
    padding-bottom: 15px;
  }
}

.details-bottom-sec {
  padding: 0 0px 0 0px;
  max-width: 1440px;
  margin: auto;
}

.right-side-floater {
  display: flex;
  flex-direction: column;
  position: sticky;
  float: right;
  top: 88px;
  z-index: 10;
  width: 25%;
}
.price-book-floater {
  background-color: #fff;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 320px;
  padding: 14px;
  margin: 0 0 15px 30px;
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(
      to right,
      #9a7013,
      #d6b677,
      #9e730f,
      #ffe083,
      #c39118,
      #e2b85c
    )
    1;
}

.total-contribute {
  position: relative;
}
.info-icon {
  margin-left: 5px;
}
.tooltip-right {
  top: -53px;
  left: 105%;
}
.total-contribute .show-total-tooltip {
  display: none;
  position: absolute;
  width: 145px;
  background: #ffffff;
  color: #333;
  padding: 5px 10px;
  line-height: 1.5;
  font-size: 12px;
  border: 1px solid black;
  z-index: 1;
}
.total-contribute .show-total-tooltip span {
  font-family: "Quicksand", sans-serif !important;
}
.info-icon:hover + .show-total-tooltip {
  display: block;
}
.total {
  font-family: "Playfair Display", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #262525;
}

.totalSubDetail {
  margin-left: 3px;
  margin-top: 0;
}
.sub-detail,
.totalSubDetail {
  margin-top: 10px;
  font-size: 12px;
  font-family: "Quicksand", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: #5a1740;
}

.floater-price {
  margin-bottom: 25px;
}
.price,
.floater-price,
.floater-detail-price,
.right-floater-price {
  font-weight: 600;
  font-size: 20px;
  color: #df7900;
  margin-bottom: 0;
}
.details-book-now .bookbut {
  width: 100%;
  text-align: center;
}
.price-book-floater .details-book-now {
  width: 110px;
}

.details-heading h2,
.details-bottom-sec h2,
.samagri-container h2,
.special-notes-sec h2,
.how-it-works h2,
.faq h2,
.something-else-container h2,
.samagri-popup-heading-container h2 {
  font-family: "Fraternite", sans-serif !important;
  color: #5a1740;
  font-weight: normal;
  font-style: normal;
  font-size: 40px;
  line-height: 50px;
  padding-right: 1rem;
}

.details-sub-head {
  display: inline-flex;
  align-items: center;
  padding-left: 1rem;
}

.details-sub-head img {
  max-width: 30px;
  margin-right: 0.5rem;
}

.details-price img {
  max-width: 20px;
  margin-right: 10px;
}
.gold-line {
  width: 400px;
  height: 2px;
}
.summaryhistory {
  border: 1px solid #cfcfcf;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  padding: 25px 30px;
  background-color: #ffffff; //added the background color
}
.details-availability {
  width: 100%;
}

.availability-head h4,
.procedure-title,
.history-sub-title {
  margin-right: 5px;
  display: inline-block;
  font-family: "Quicksand" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #5a1740;
}
.hiddenTag {
  display: none;
}
.procedure-title {
  margin-top: 30px;
}

.procedure-description {
  margin-top: 10px;
  font-family: "Quicksand", sans-serif !important;
  font-size: 16px;
  font-weight: 500;
}

.history-sub-title {
  margin-bottom: 10px;
}

.note-sec-procedure {
  padding: 25px 50px 25px 25px;
  margin-top: 15px;
  background: rgb(209, 162, 225, 0.3);
  line-height: 26px;
  font-weight: 500;
  border-width: 1.5px;
  border-style: solid;
  border-image: linear-gradient(
      to right,
      #9a7013,
      #d6b677,
      #9e730f,
      #ffe083,
      #c39118,
      #e2b85c
    )
    1;
}

.availability-head span {
  width: 82%;
}

.availability-form {
  gap: 2rem;
  display: block;
}

.input-wrapper {
  position: relative;
}

.availability-form form input {
  height: 46px;
  width: 350px;
  border: 1px solid #a7a7a7;
  box-sizing: border-box;
  padding: 0 1rem;
}

.input-icon {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 1rem;
}

.sucess-location {
  gap: 0.5rem;
}

.sucess-location img {
  height: 1rem;
}

.sucess-location p {
  font-size: 14px;
  color: #1e9a57;
}

.material-availablility p {
  font-size: 14px;
}

.material-availablility h6 {
  font-size: 16px;
  line-height: 26px;
}

.available-modes {
  gap: 35px;
  padding-bottom: 0 !important;
  margin-bottom: 20px;
}

.available-box {
  gap: 0.5rem;
}

.available-box img {
  width: 24px;
}

.available-box p {
  font-family: "Quicksand" !important;
  font-size: 16px;
  padding-top: 20px;
  color: #262525;
  font-weight: 500;
}

.available-modes-mobile-container {
  display: none;
}

.puja-details-benefits ul {
  margin-bottom: 2rem;
}

.puja-details-benefits ul li {
  margin: 12px 0 0 0;
  padding-left: 42px;
  line-height: 31px;
}

.puja-details-benefits ul li:first-child {
  margin: 0;
}
.up-down-icon {
  display: none;
}

.puja-details-disclaimer {
  background: rgba(214, 138, 184, 0.1);
  padding: 2rem;
  border-radius: 1rem;
}

.puja-details-disclaimer h4 {
  font-family: "Fraternite";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #5a1740;
}

.puja-details-disclaimer p {
  font-size: 14px;
}

.procedure-bottom-image-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.massage {
  text-align: center;
  margin-bottom: 18px;
}

p.success-msg {
  color: green;
  font-size: 20px;
}

.right-side-floater {
  display: flex;
  flex-direction: column;
  position: sticky;
  float: right;
  top: 88px;
  z-index: 10;
  width: 25%;
}

.details-show-display,
.details-hide-display {
  display: none;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
  cursor: pointer;
  margin-left: 30px;
}

.details-show-display p,
.details-hide-display p {
  font-family: "Quicksand" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.01em;
  color: #5a1740;
}

.details-hide-display {
  display: flex;
}

.price-book-floater {
  background-color: #fff;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 320px;
  padding: 14px;
  margin: 0 0 15px 30px;
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(
      to right,
      #9a7013,
      #d6b677,
      #9e730f,
      #ffe083,
      #c39118,
      #e2b85c
    )
    1;
}

.price-book-floater .details-book-now {
  width: 110px;
}

.link-container {
  background-color: #ffffff;
  border: 1px solid #cfcfcf;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  padding-right: 40px;
  margin: 15px 0 0 20px;
  font-family: "Playfair Display";
  font-weight: 500;
  font-size: 20px;
  line-height: 34px;
  max-height: 385px;
}

.link-container-link {
  margin-top: 20px;
}

.link-container-links div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 45px;
}

.link-container div a {
  color: #5a1740 !important;
  white-space: nowrap;
  font-family: "Playfair Display" !important;
  font-weight: 500;
  font-size: 20px;
}

.link-container-links div img {
  display: none;
  margin-right: -18px;
}

.link-container-navigate-stick {
  position: absolute;
  right: 28px;
}

.link-container-navigate-stick img {
  height: 320px;
}

.diya-active {
  display: inline-block !important;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10;
}

.modal-body iframe {
  width: 100% !important;
  height: 500px !important;
}

/* Samagri */
.samagri-outer-container {
  display: none;
  justify-content: center;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.samagri-container {
  margin: 50px 0;
}

.samagri-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.samagri-header img {
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.samagri-box {
  background-color: #fff;
  padding: 40px 50px 0;
  border: 1px solid #cfcfcf;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  height: 100%;
  overflow-y: auto;
}

.pujari-cost-remove-btn {
  margin-bottom: 10px;
}

.samagri-cost-add-btn {
  margin-bottom: 10px;
}

.samagri-box-inner {
  margin-top: 40px;
  border-width: 1.5px;
  border-style: solid;
  border-image: linear-gradient(
      to right,
      #9a7013,
      #d6b677,
      #9e730f,
      #ffe083,
      #c39118,
      #e2b85c
    )
    1;
}

.samagri-heading {
  display: inline;
  font-family: "Quicksand";
  font-size: 20px;
  font-weight: 600;
  color: #5a1740;
}

.samagri-items,
.samagri-what-you-need-items {
  line-height: 40px;
}
.samagri-items {
  position: relative;
}
.separte_line {
  width: 1px;
  height: 100%;
  background: #cfcfcf;
  position: absolute;
  top: 0;
  left: 49%;
}
@media screen and (max-width: 995px) {
  .separte_line {
    display: none;
  }
}
.samagri-items ul,
.samagri-what-you-need-items ul,
.samagri-list {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  list-style-position: inside;
}
.samagri-what-you-need-items ul {
  position: relative;
}
.samagri-items ul hr {
  display: none;
}

.samagri-items .samagri-item-name {
  display: -webkit-inline-box;
  width: 400px;
  font-family: "Quicksand", sans-serif !important;
}
.samagri-items ul,
.samagri-list {
  list-style-image: url(../../../public/assets/images/samagri-list-items-icon.png);
}

.samagri-item-quantity {
  display: -webkit-inline-box;
  width: 90px;
  font-family: "Quicksand", sans-serif !important;
}

.bookbut {
  text-decoration: none;
  color: white !important;
  cursor: pointer;
}
.bookbut:hover {
  color: white !important;
}
.samagri-items-container {
  background-color: #fff;
  border-bottom: 1.5px;
  border-bottom-style: solid;
  border-image: linear-gradient(
      to right,
      #9a7013,
      #d6b677,
      #9e730f,
      #ffe083,
      #c39118,
      #e2b85c
    )
    1;
  padding: 30px;
  padding-right: 10px;
}

.samagri-what-you-need {
  background-color: rgb(209, 162, 225, 0.3);
  padding: 30px;
}

.samagri-you-can-also-buy {
  padding: 30px;
  border-bottom: 1.5px;
  border-bottom-style: solid;
  border-image: linear-gradient(
      to right,
      #9a7013,
      #d6b677,
      #9e730f,
      #ffe083,
      #c39118,
      #e2b85c
    )
    1;
}

.samagri-list-container,
.procedure-list-container {
  background-color: #fff;
  border: 1.5px;
  border-style: solid;
  border-image: linear-gradient(
      to right,
      #9a7013,
      #d6b677,
      #9e730f,
      #ffe083,
      #c39118,
      #e2b85c
    )
    1;
  padding: 20px;
  overflow: auto;
}

.my-booking-samagri {
  padding: 0 0 0 30px;
}

.additional-samagri-heading-container {
  margin-bottom: 20px;
}

.gold-star-dots {
  display: none;
}

.samagri-items,
.samagri-what-you-need-items {
  margin-top: 20px;
}

.samagri-what-you-need-items ul::after {
  content: "";
  width: 1px;
  height: 100%;
  background-color: #cfcfcf;
  display: block;
  position: absolute;
  top: 0px;
  right: 51%;
}

.samagri-text {
  display: flex !important;
}

.samagri-text div {
  margin-left: 10px;
}

@media screen and (max-width: 479px) {
  .frthbtmcontsec h4 {
    font-size: 34px !important;
    line-height: 36px !important;
  }
  .samagri-container {
    margin: 0px !important;
  }
}
@media screen and (max-width: 992px) {
  .samagri-what-you-need-items ul::after {
    display: none;
  }
  .pujari-cost-remove-btn {
    margin-bottom: 10px;
  }
}

.samagri-list li {
  margin-bottom: 12px;
}

.samagri-bottom-image-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.special-notes-sec {
  display: flex;
  flex-direction: column;
  margin: 35px 0;
}

.notes-input {
  border: 1px solid #cfcfcf;
  height: 100px;
  width: 100%;
  padding: 15px 20px;
}

.special-notes-sec h2 {
  margin-bottom: 6px;
  font-family: "Nunito Sans";
}

.special-notes-sec p {
  margin-bottom: 10px !important;
  font-size: 16px;
  font-family: "Quicksand", sans-serif !important;
  font-weight: 500;
}

.special-notes-img {
  align-self: center;
  width: 180px;
  height: 180px;
}

.you-can-buy-list {
  max-height: 360px;
  overflow: auto;
  position: relative;
}

.you-can-buy-list-top {
  height: fit-content !important;
}

@media screen and (max-width: 480px) {
  .you-can-buy-list-top {
    padding-bottom: 0px !important;
  }
}

.you-can-buy-list li {
  display: flex;
  border-bottom: 1px solid #cfcfcf;
  padding: 12px;
  align-items: center;
}

.you-can-buy-list li:first-child p {
  font-weight: 600;
}

.you-can-buy-list li p:nth-child(1) {
  width: 380px;
  overflow-wrap: anywhere;
  padding-right: 40px;
}

.you-can-buy-list li p:nth-child(2) {
  width: 150px;
}

.you-can-buy-list li p:nth-child(3) {
  width: 80px;
}

.you-can-buy-list li p:nth-child(4) {
  width: 80px;
  text-align: right;
  margin-left: auto;
}

.price,
.floater-price,
.floater-detail-price,
.right-floater-price {
  font-weight: 600;
  font-size: 20px;
  color: #df7900;
  margin-bottom: 0;
}

.floater-price {
  margin-bottom: 25px;
}

.you-can-buy-list li .buy-item-price {
  font-weight: 600;
  color: #df7900;
  font-size: 20px;
}

.add-button {
  border: 1px solid #5a1740;
  border-radius: 40px;
  width: 60px;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #5a1740;
  background-color: #fff;
  margin-left: auto;
  height: fit-content;
}

.remove-button {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 0;
  color: #5a1740;
  background-color: #fff;
  border: 0;
  margin-left: auto;
}

.remove-button img {
  width: 14px;
  height: 14px;
  margin-right: 4px;
}

.add-button-mobile,
.remove-button-mobile {
  display: none;
}

.add-button:disabled,
.add-button-mobile:disabled {
  opacity: 0.5;
  background-color: unset;
  color: unset;
}

.all-items-checkbox {
  padding: 0 30px 25px;
  background-color: rgb(209, 162, 225, 0.3);
  display: flex;
  justify-content: flex-end;
}

.all-items-checkbox div {
  display: flex;
  align-items: center;
}

.all-items-checkbox div input {
  accent-color: #5a1740;
  height: 14px;
  width: 14px;
}

.all-items-checkbox div label {
  margin-left: 7px;
}

/* History */
.history-container {
  margin-top: 50px;
}

.heading-something-else-mobile {
  font-family: "Nunito Sans" !important;
}

.puja-details-sec {
  font-family: "Nunito Sans" !important;
}

.details-heading h2 {
  font-family: "Nunito Sans" !important;
}

.history-inner-container {
  background-color: #fff;
  margin-top: 15px;
  line-height: 28px;
  padding: 50px;
  padding-top: 10px;
  border: 1px solid #cfcfcf;
  box-shadow: 0px 4px 16px rgb(0 0 0 / 8%);
  width: 100%;
}
.card-text {
  margin-bottom: 0 !important;
  font-weight: 500;
  font-family: "Quicksand", sans-serif !important;
}
.history-inner-container p {
  line-height: 28px;
  font-family: "Quicksand", sans-serif !important;
  font-size: 16px;
  font-weight: 500;
}

.history-inner-sub-box {
  align-items: center;
  margin: 40px 0 0px;
}

.history-image-div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.insight-card {
  padding: 18px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
}

.video-thumb {
  width: 100%;
}

.insight-card .video-desc {
  display: none;
  margin-top: 10px;
}
.insight-video-container {
  display: none;
}

.history-mobile {
  display: none;
}

.history-inner-container ul {
  padding-left: 25px;
}

.history-inner-container ul,
.history-inner-container ul li {
  list-style-type: unset;
}

/* How it works */
.how-it-works {
  margin-top: 50px;
  background-image: url("../../../public/assets/images/how-it-works-design.png");
  background-repeat: no-repeat;
  padding-bottom: 50px;
  background-position-y: -22px;
  background-position-x: -22px;
}

.how-it-works-mobile,
.zig-zag-container-mobile,
.history-container-mobile {
  display: none;
}

.zig-zag-container {
  display: grid;
  max-width: 100%;
}

.arrow-card-div {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.zig-zag-container .arrow-card-div:nth-child(3) {
  justify-content: flex-start;
}

.card {
  height: 215px;
  max-width: 620px;
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #cfcfcf;
  border-radius: 0;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  padding: 20px 50px;
  margin-top: 30px;
}

.card-img {
  height: 180px;
  width: 180px;
}

.card .gold-star-point {
  position: relative;
  margin-right: 5px;
}

/* FAQ */
.faq {
  margin: 50px 0;
}

.faq-container {
  border: 1px solid #cfcfcf;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  padding: 50px;
  padding-top: 35px;
  margin-top: 15px;
  position: relative;
  width: 100%;
  background-color: #ffffff;
}

.faq-container::before {
  content: "";
  background-image: url("../../../public/assets/images/faq-question.png");
  background-repeat: no-repeat;
  top: 30px;
  right: 20px;
  position: absolute;
  opacity: 0.3;
  width: 292px;
  height: 250px;
}

.faq-mobile,
.faq-container-mobile {
  display: none;
}

.faq-container-inner ul {
  list-style-image: url("../../../public/assets/images/gold-star.png");
  margin-bottom: 0 !important;
}

/* FAQ Accordion */
.faq-list > li > .accordion {
  background-color: unset;
  cursor: pointer;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  font-family: "playfair display", sans-serif !important; //added the font family
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #262525;
  display: flex;
  position: relative;
  z-index: 2;
}

.panel-text {
  font-family: "playfair display", sans-serif !important; //added the font family
  font-size: 14px;
  color: #262525;
  font-weight: 400;
}

.faq-arrow-icon {
  margin-left: auto;
  align-self: center;
}

.panel {
  padding-top: 15px;
  display: none;
  overflow: hidden;
  position: relative;
  z-index: 2;
}

.faq-list li {
  border-bottom: 1px solid #cfcfcf;
  width: 100%;
  padding: 14px 0;
}

.note-sec {
  width: 100%;
  padding: 25px 50px 25px 25px;
  margin-top: 25px;
  margin-bottom: 20px;
  background: rgb(209, 162, 225, 0.3);
  line-height: 26px;
  font-weight: 500;
  border-width: 1.5px;
  border-style: solid;
  border-image: linear-gradient(
      to right,
      #9a7013,
      #d6b677,
      #9e730f,
      #ffe083,
      #c39118,
      #e2b85c
    )
    1;
}

.note-bold {
  font-weight: 700;
}

.something-else-divider {
  width: 100%;
  border: 2px solid #cfcfcf;
  margin-top: 50px;
}

.something-else-container {
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 0 !important;
}

.carousel-container {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
}

.puja-card {
  padding: 30px;
  background: #ffffff;
  border: 1px solid #cfcfcf;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  width: 360px;
  margin-right: 50px;
  cursor: pointer;
}

.puja-image {
  width: 100%;
  height: 160px;
  border: 0.5px solid #5a1740;
}

.puja-title {
  font-family: "Playfair Display" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #262525;
  margin: 20px 0 10px;
  min-height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.puja-desc {
  font-family: "Quicksand" !important;
  font-size: 14px;
  font-weight: 500;
  min-height: 72px;
  line-height: 24px;
  letter-spacing: 0.01em;
  margin-bottom: 20px;
  color: #262525;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.puja-card div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
}

.puja-price {
  font-family: Quicksand;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: #df7900;
}

.puja-price span {
  display: block;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #262525;
}

.puja-book-button {
  border: 1px solid #5a1740;
  color: #5a1740;
  background-color: #fff;
  width: 130px;
  height: 40px;
}

.puja-book-button a {
  color: #5a1740 !important;
  font-family: "Quicksand" !important;
}

.offerings-pujas {
  font-weight: 500;
}

.slideshow-container {
  display: none;
}

.heading-something-else-mobile {
  display: none;
}

.bottom-price-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
}

/* RITUAL-BOOKING ************************************/
.ritual-booking-main-wrapper {
  padding-bottom: 3rem;
  position: relative;
  z-index: 2;
}

.ritual-booking-main-wrapper::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  background-size: cover;
  z-index: -1;
}

.ritual-top-inner {
  background: #fff;
  padding: 2rem;
  gap: 2rem;
  border: 1px solid #cfcfcf;
  margin-bottom: 2rem;
}

.ritual-top-img-wrapper {
  width: 25%;
}

.ritual-top-heading {
  width: 75%;
}

.ritual-top-heading h2 {
  font-family: "Fraternite";
  color: #5a1740;
  font-weight: normal;
  font-style: normal;
  font-size: 40px;
  line-height: 50px;
}

.ritual-booking-details-box {
  background: #fff;
  padding: 1.5rem;
  border: 1px solid #cfcfcf;
  position: relative;
}

.ritual-head-line {
  margin-bottom: 25px;
}

.ritual-head-line h4 {
  font-size: 20px;
  display: inline-block;
  background: #fff;
  padding: 0 15px 0 0;
}

.ritual-call a {
  font-size: 16px;
  color: #000000;
}

.ritual-call img {
  width: 16px;
  margin-right: 0.5rem;
}

.ritual-form-wrapper label {
  margin-bottom: 0.5rem;
}

.ritual-form-wrapper input[type="text"],
.ritual-form-wrapper input[type="number"],
.ritual-form-wrapper input[type="date"],
.ritual-form-wrapper input[type="time"],
.ritual-form-wrapper input[type="tel"],
.ritual-form-wrapper select {
  width: 100%;
  padding: 0 0.5rem;
  height: 46px;
  border: 1px solid #a7a7a7;
  background: #fff;
}

.mode-input-boxes {
  gap: 1rem;
}

.mode-input-box {
  width: 220px;
  height: 50px;
  border: 1px solid #cfcfcf;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12);
  gap: 0.5rem;
  justify-content: center;
  display: flex;
  align-items: center;
}

.mode-input-box img {
  width: 24px;
}

.ritual-bottom-inner {
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: end;
}

.ritual-bottom-inner p {
  font-size: 20px;
  font-weight: 500;
  color: #262525;
  line-height: 50px;
  display: none;
}

.details-price {
  display: none;
}

.ritual-bottom-inner .ritual-submit-btn {
  position: relative;
}

.ritual-submit-btn .bookbut {
  margin: 0;
  height: 50px;
  background: #5a1740;
  padding: 0 30px;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  line-height: normal;
  font-family: "Quicksand", sans-serif;
  border: 0;
  outline: 0;
  text-transform: capitalize;
  width: 100%;
}

.ritual-bottom-inner .ritual-submit-btn .wpcf7-spinner {
  margin: 0 !important;
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 1;
}

.image-slideshow-container {
  position: relative;
  margin: auto;
}

.img-slide {
  display: none;
}

.img-slide img {
  width: 1236px;
  height: 500px;
}

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  user-select: none;
}

.prev {
  left: 0;
}

.next {
  right: 0;
}

.img-dot-container {
  position: relative;
  top: -80px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.change-img-dot {
  cursor: pointer;
  height: 8px;
  width: 20px;
  margin: 20px 6px 0;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 12px;
  border: 2px solid #5a1740;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.change-img-dot.active,
.change-img-dot:hover {
  background-color: #5a1740;
  border-radius: 12px;
  width: 40px;
}

.puja-features {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  margin-left: -8px;
}

.puja-features > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.puja-features > div > img {
  height: 60px;
  width: 60px;
}

.details-head-sec {
  display: flex;
  flex-direction: column;
  justify-self: start;
  padding: 6rem 45px 2rem;
  margin-top: -185px;
}

.details-head-lft {
  width: 100%;
  position: relative;
  border: 1px solid #cfcfcf;
  border-right: none;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
}

.details-head-rht {
  width: 50%;
  padding: 10px;
  padding-right: 40px;
  background-color: #fff;
  display: none;
  flex-direction: column;
  z-index: 1;
  border: 1px solid #cfcfcf;
  border-left: 0;
}

.invoice-and-samagri {
  padding: 0 10px 20px;
  margin: 0 0 10px 10px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  background: #ffffff;
  border: 1px solid #cfcfcf;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  font-size: 14px;
}

.border-line {
  border: 1px solid #cfcfcf;
  margin-bottom: 20px;
}

.puja-heading-mobile {
  display: none;
}

.price-overview,
.details-display-show,
.details-display-hide {
  display: none;
}

.details-display-show,
.details-display-hide {
  cursor: pointer;
}

.details-head-rht .details-book-now {
  width: 100%;
}

.details-head-rht .details-book-now a.bookbut {
  padding: 0 40px;
}

.details-book-now {
  width: 253px;
}

.details-book-now .bookbut {
  width: 100%;
  text-align: center;
}

.samagri-done {
  cursor: pointer;
}

.donebtn {
  background: #5a1740;
  line-height: 50px;
  color: #fff;
  font-weight: 500;
  font-family: "Quicksand", sans-serif;
  display: block;
  float: left;
  padding: 0 20px;
  border: 1px solid #5a1740;
}

.details-head-rht h2 {
  color: #df7900;
  font-weight: 600;
  font-size: 32px;
  line-height: normal;
}

.price-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px solid #e0cce6;
  padding: 14px 0;
}

.price-box:nth-child(1) {
  border: 0;
}

.price-box div {
  display: flex;
  justify-content: space-between;
}

.price-box > div > .total-incl-tax {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.price-box .details-book-now-mobile {
  display: none;
}

.edit-service-charges,
.edit-service-charges-floater {
  padding: 14px 10px 14px 0;
  border-bottom: 1px solid #e0cce6;
  display: none;
}

.service-charge-input {
  width: 100%;
  height: 46px;
  border: 1px solid #a7a7a7;
  margin-right: 20px;
  padding-left: 15px;
}

.apply-charge-btn {
  width: 100px;
  height: 46px;
  border: 1px solid #5a1740;
  background-color: #fff;
  color: #5a1740;
}
.view-btn {
  color: #5a1740 !important;
  font-weight: 500;
  text-decoration: none;
  font-family: "Quicksand", sans-serif !important;
}
.view-btn:hover {
  color: #0d6efd !important;
}
.samagri-additional-btn {
  width: 80px;
  height: 25px;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: white;
  background-color: #5a1740;
  border: 1px solid #5a1740;
  display: flex;
  justify-content: center !important;
  align-items: center;
  cursor: pointer !important;
  margin-left: 10px;   
  &:hover {
    background-color: #761E54;
  }    //changed the button hover color
}

.samagri-cost-add-btn {
  width: 50px;
  height: 25px;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: white;
  background-color: green;
  border: 1px solid #5a1740;
  display: flex;
  justify-content: center !important;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: rgb(17, 109, 17);
  }
}

.samagri-additional-btn a {
  color: #5a1740;
}
.edit-service-btn {
  margin-top: 10px;
}
.model {
  font-size: 20px;
  font-weight: 600;
  font-family: Quicksand;
  color: #df7900;
  margin-bottom: 15px;
}

.total {
  font-family: "Playfair Display" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #262525;
}

.price-header {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 24px;
  color: #262525;
}

.sub-detail,
.totalSubDetail {
  margin-top: 10px;
  font-size: 12px;
  font-family: "Quicksand" !important;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: #5a1740;
}

.total-amount {
  display: inline !important;
}
.total-amount-container {    //fixed alignment issue 
  display: flex !important;
  align-items: center;
}
.hide-total-amount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
}
.totalSubDetail {
  margin-left: 3px;
  margin-top: 0;
}
.info-icon {
  margin-left: 5px;
  width: 16.5px;
  height: 16.5px;
}
.view-samagri-btn {   //fixed alignment issue 
  color: #5a1740 !important;
  font-weight: 600;
  text-decoration: none;
  margin-bottom: 10px;
  margin-left: 10px;
}
.view-samagri-btn:hover {
  color: #0d6efd !important;
}
.samagri-invoice-col {
  display: flex;
}
.details-head-rht .price-box:nth-child(1) {
  padding: 14px 14px 8px 0;
}

.details-head-rht .price-box:nth-child(8) {
  border-bottom: 0;
}

.details-head-lft::after {
  width: 0.5px;
  height: 130px;
  top: 50%;
  right: -0.5rem;
  transform: translate(-50%, -50%);
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
}

.heading-wrapper {
  position: relative;
  margin-bottom: 15px;
}
.summary-underline-img {
  margin: 25px 0 50px;
  width: 100%;
}

.summary-text {
  margin-top: 12px;
  font-family: "Quicksand", sans-serif !important;
  font-size: 16px;
  font-weight: 500;
}
.summary-heading {
  font-family: "Nunito Sans" !important;
}
.heading-wrapper::after {
  width: 0.5px;
  height: 20px;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
}

.puja-details-benefits h2 {
  font-size: 30px !important;
}
.details-summary-and-link-container {
  display: flex;
}

.details-heading {
  background-color: #fff;
  padding: 35px;
  padding-bottom: 15px;
}

.PoojaDeatailpage {
  margin-top: 121px;
}

.gold-line {
  max-width: 100%;
  height: auto;
}

.puja-card-line {
  text-decoration: none !important;
}

.puja-book-button a {
  text-decoration: none !important;
}
.you-can-buy-list li {
  display: flex;
  border-bottom: 1px solid #cfcfcf;
  padding: 12px;
  align-items: center;
}
.you-can-buy-list {
  max-height: 360px;
  overflow: auto;
  position: relative;
}
.you-can-buy-list li p:nth-child(1) {
  width: 380px;
  overflow-wrap: anywhere;
  padding-right: 40px;
}
.you-can-buy-list li p {
  font-weight: 600;
}
.samagri-desc-span {
  display: block;
  font-size: 12px;
  color: #333;
}
.add-button {
  border: 1px solid #5a1740;
  border-radius: 40px;
  width: 60px;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #5a1740;
  background-color: #fff;
  height: fit-content;
}
.view-samagri-button {
  display: none;
}
.samagri-item-name-col {
  display: flex;
  flex-direction: column;
}
.link-container {
  a {
    text-decoration: none !important;
  }
}
.procedure-special-notes {
  padding: 25px 50px 25px 25px;
  margin-top: 15px;
  background: rgb(209, 162, 225, 0.3);
  line-height: 26px;
  border-width: 1.5px;
  border-style: solid;
  border-image: linear-gradient(
      to right,
      #9a7013,
      #d6b677,
      #9e730f,
      #ffe083,
      #c39118,
      #e2b85c
    )
    1;
  color: black;
  font-weight: 600;
  font-family: "Playfair Display", serif;
}
.margin-button {
  margin-left: 10px;
}
.price-box-samagri {
  display: block;
}
@media screen and (max-width: 1400px) and (min-width: 991px) {
  .view-samagri-btn {
    display: none;
  }
  .view-samagri-button {
    display: block;
    color: #5a1740 !important;
    font-weight: 600;
    text-decoration: none;
  }
}
@media screen and (max-width:1199px) and (min-width:991px) {
  .price-box-samagri{
    flex-direction: column !important;
  }
  .pujari-button{
    display: flex;
    flex-direction: column !important;
  }p{
    margin-bottom: 10px !important;
  }
  .samagri-text{
    margin-bottom: 10px !important;
  }
  .numberOfPujaries{
    display:none;
  }
}
@media screen and (max-width: 2560px) and (min-width: 1921px) {
  .details-head-sec {
    max-width: 1700px;
    margin: -185px auto 0;
    padding: 2rem 0;
  }
  .puja-container {
    width: 1760px;
  }
  .img-slide img {
    width: 100%;
    height: 700px;
  }
  .details-bottom-sec {
    max-width: 1700px;
    margin: 20px auto 0;
    padding: 0;
  }
  .price-book-floater {
    max-width: 100%;
  }
  .how-it-works-sec-card {
    max-width: unset;
    min-height: 400px;
  }
  .zig-zag-container {
    max-width: 1100px;
  }
  .details-head-rht .details-book-now {
    left: 600px;
  }
  .you-can-buy-list li p {
    width: unset;
    flex: 1;
  }
  .you-can-buy-list li p:nth-child(1) {
    flex: 2;
  }
  .you-can-buy-list li p:nth-child(4) {
    width: 60px;
    flex: none;
  }
}
@media screen and (max-width: 1920px) and (min-width: 1600px) {    //made responsive 
  .details-head-lft {
    width: 1500px;
    margin: 0 auto;
  }
  .puja-features {
    margin-bottom: 80px;
  }
  .book-puja-container {
    margin-top: 30px;
  }
  .img-slide img {
    width: 1500px;
    height: 500px;
  }
  .details-bottom-sec {
    width: 1500px;
    margin: 50px auto 0;
    padding: 0;
  }
  .price-book-floater {
    width: 93%;
    max-width: unset;
  }
  .price-book-floater .details-book-now {
    width: 150px;
  }
  .details-head-rht .details-book-now {
    left: 600px;
  }
  .how-it-works-sec-card {
    max-width: 580px;
  }
}
@media screen and (max-width: 1600px) {
  .details-head-sec {
    padding: 6rem 150px 2rem;
  }
}
@media screen and (max-width: 1536px) {
  .details-head-sec {
    padding: 6rem 118px 2rem;
  }
}

@media screen and (max-width: 1440px) {
  .details-head-sec {
    padding: 6rem 70px 2rem;
  }
}

@media screen and (max-width: 1399px) {
  .details-head-sec {
    padding: 5rem 33px 2rem;
  }
  .puja-features > div {
    margin-right: 54px;
  }
  .details-bottom-sec {
    padding: 0 35px 0 70px;
  }
  .right-side-floater {
    width: 30%;
  }
  .invoice-and-samagri {
    padding: 0 15px 20px;
  }
}
@media screen and (max-width: 1350px) {
  .img-slide img {
    width: 1200px;
  }
}
@media screen and (max-width: 1280px) {
  .details-bottom-sec {
    padding: 0 20px 0 40px;
  }
  .invoice-and-samagri {
    margin: 0 0 20px 20px;
  }
  .puja-card {
    width: 300px;
    margin-right: 20px;
    padding: 20px;
  }
}
@media screen and (max-width: 1264px) {
  .details-head-sec {
    padding: 3rem 30px 2rem;
    flex-direction: column;
  }
  .details-head-lft,
  .details-head-rht {
    width: 100%;
    border: 1px solid #cfcfcf;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  }
  .img-slide img {
    width: 1140px;
  }
  .details-head-rht {
    padding-left: 40px;
  }
}
@media screen and (max-width: 1199px) {
  .img-slide img {
    width: 965px;
    height: 425px;
  }
  .right-side-floater {
    width: 26%;
  }
  .price-book-floater p {
    font-size: 14px;
  }
  .link-container-links div {
    flex-direction: column;
  }
  .zig-zag-container {
    max-width: 770px;
  }
  .zig-zag-container .arrow-card-div:nth-child(3) .arrow {
    margin: 0 30px 0 0;
  }
}

@media screen and (max-width: 1024px) {
  .details-head-sec {
    flex-direction: column;
    padding: 3.5rem 30px 2rem;
  }
  .details-bottom-sec {
    padding: 0 20px 0 40px;
  }
  .price-box div {
    flex-direction: row;
  }
  .link-container-links div img {
    margin-right: -30px;
    margin-bottom: -10px;
  }
  .link-container-links {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .zig-zag-container {
    max-width: 700px;
  }
  .samagri-box {
    width: 100%;
    height: 100%;
  }
  .zig-zag-container .arrow-card-div:nth-child(3) .arrow {
    margin: 0 30px 0 0;
  }
  .samagri-items ul li {
    margin-left: 0;
  }
}
@media screen and (max-width: 1050px) {
  .price-box {
    padding: 14px 0;
  }
  .invoice-and-samagri {
    padding: 0 10px 20px;
  }
}
@media screen and (max-width: 991px) {
  .details-head-sec {
    margin-top: -145px;
  }
  .details-heading {
    padding: 20px;
    padding-bottom: 15px;
  }
  .puja-features > div {
    margin-right: 25px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .book-puja-container-tabsec {
    display: block;
    margin-bottom: 70px;
  }
  .puja-container {
    background-size: 100% 90% !important;
  }
  .samagri-items .samagri-item-name {
    display: -webkit-inline-box;
    width: 270px;
    font-family: "Quicksand", sans-serif !important;
  }
  .img-slide img {
    width: 710px;
    height: 350px;
  }
  .details-bottom-sec {
    padding: 0 30px;
  }
  .price-book-floater {
    display: none;
  }
  .details-show-display {
    display: none;
  }
  .invoice-and-samagri {
    display: none !important;
  }
  .details-hide-display {
    display: none;
  }
  .link-container {
    display: none;
  }
  .how-it-works {
    background-position-x: -100px;
  }
  .how-it-works-container {
    margin: 20px 0;
  }
  .how-it-works-sec-card {
    padding: 20px;
  }
  .how-it-works-sec-card img {
    height: 140px;
    width: 140px;
  }
  .search-section-heading {
    margin-top: 75px;
  }
  .details-head-rht {
    display: flex;
    padding: 20px 40px;
    margin-top: 60px;
  }
  .samagri-box {
    padding: 30px;
    padding-bottom: 0;
  }
  .samagri-items ul,
  .samagri-what-you-need-items ul {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
    list-style-position: inside;
    font-size: 14px;
  }
  .you-can-buy-list li p:nth-child(1) {
    width: 230px;
  }
  .you-can-buy-list li p:nth-child(2) {
    width: 120px;
  }
}
@media screen and (max-width: 899px) and (min-width: 826px) {
  .img-slide img {
    max-width: 750px;
  }
}
@media screen and (max-width: 825px) and (min-width: 769px) {
  .img-slide img {
    width: 700px;
  }
}
@media screen and (max-width: 767px) {
  .details-head-sec {
    display: block;
    padding-bottom: 1rem;
  }
  .details-head-lft,
  .details-head-rht {
    width: 100%;
  }
  .details-head-lft:after {
    display: none;
  }
  .details-heading h2 {
    font-size: 32px;
    line-height: normal;
    font-family: "Nunito Sans", sans-serif;
  }
  .available-modes,
  .availability-head {
    margin-top: 20px;
  }
  .available-box {
    width: auto !important;
  }
  .puja-features {
    margin-left: -10px;
  }
  .puja-features {
    display: grid !important;
    grid-template: repeat(3, 1fr) / repeat(2, 1fr);
    gap: 10px;
    margin-bottom: 10px;
    margin-left: -6px;
  }
  .puja-features > div {
    min-width: unset;
    height: 50px;
    flex-direction: row;
    margin-right: 0;
    justify-content: flex-start;
    text-align: left;
  }
  .puja-features > div:last-child {
    grid-column: 1 / -1;
  }
  .puja-features > div > img {
    height: 40px;
    width: 40px;
  }
  .puja-features > div > p {
    font-size: 14px !important;
  }
  .img-slide img {
    width: 600px;
  }
  a.bookbut {
    display: inline-block;
  }
  .how-it-works-container-mblsec {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px 0px;
  }
  .how-it-works-sec-card-mblsec {
    margin-right: 0px;
    padding: 0px 23px 30px;
    margin-bottom: 20px;
  }
  .carousel-container {
    display: block;
    align-items: center;
  }
  .details-head-lft,
  .details-head-rht {
    width: 100%;
  }
  .details-head-rht {
    margin-top: 20px;
  }
}
@media screen and (max-width: 768px) {
  .img-slide img {
    width: 700px;
  }
  .topftrsec .container a.bookbut {
    width: 140px;
    height: 42px;
  }
  .arrow {
    display: none;
  }
  .card-text {
    font-size: 12px;
  }
  .card-img {
    width: 50%;
    height: 100px;
  }
  .puja-card {
    width: 340px;
    margin-bottom: 30px;
  }
  .details-heading {
    padding: 20px;
  }
  .carousel-container {
    align-items: center;
  }
}
@media screen and (max-width: 700px) {
  .search-section-container {
    background-size: 50%;
    background-position: top right;
  }
  .search-section-heading {
    width: 68%;
  }
  .top-search-section {
    margin-top: 100px;
  }
}
@media screen and (max-width: 680px) {
  .img-slide img {
    width: 500px;
  }
}
@media screen and (max-width: 599px) {
  .img-slide img {
    width: 425px;
  }
}
@media screen and (max-width: 479px) {
  .faq-container::before {
    background-image: none !important;
  }
  .details-head-sec {
    padding: 3rem 0 0;
    margin-top: -80px;
    display: flex;
    gap: unset;
  }
  .details-heading,
  .availability-head {
    display: block;
  }
  .details-heading h2 {
    padding: 0;
  }
  .details-heading h2,
  .details-bottom-sec h2,
  .samagri-container h2,
  .special-notes-sec h2,
  .how-it-works h2,
  .faq h2,
  .something-else-container h2 {
    font-size: 25px !important;
  }
  .details-heading {
    padding: 15px;
  }
  .heading-wrapper::after {
    display: none;
  }
  .available-modes-mobile-container {
    display: inline-block;
  }
  .available-modes-mobile {
    display: flex;
    justify-content: space-between;
  }
  .available-modes-mobile > .available-box {
    margin: 0 10px 20px 0;
  }
  .available-modes {
    gap: 15px;
    margin-top: 10px;
  }
  .available-box p {
    font-size: 15px;
    line-height: normal;
  }
  .puja-container {
    padding: 10px;
  }
  .book-puja-container-tabsec {
    display: none;
  }
  .img-slide img {
    width: 405px;
    height: 250px;
  }
  .info-icon {
    margin-top: 0;
  }
  .details-head-rht .details-book-now {
    display: none;
  }
  .all-items-checkbox .details-book-now {
    width: 100%;
    margin-top: 40px;
  }
  .price-box .details-book-now-mobile {
    display: flex;
    margin-top: 15px;
  }
  a.bookbut {
    display: inline-block;
    padding: 0 30px;
  }
  .price-box {
    padding-right: 0;
  }
  .price-box .details-book-now-mobile {
    display: flex;
    margin-top: 15px;
  }
  .details-heading h2,
  .details-bottom-sec h2,
  .samagri-container h2,
  .special-notes-sec h2,
  .how-it-works h2,
  .faq h2,
  .something-else-container h2 {
    font-size: 34px;
  }
  .how-it-works {
    padding-bottom: 0;
  }
  .how-it-works-mobile,
  .faq-mobile,
  .history-mobile {
    background-color: #ffffff;
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #cfcfcf;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
    margin: 60px 20px 20px 20px;
  }
  .how-it-works,
  .how-it-works-mobile {
    margin-top: 0;
  }
  .how-it-works-mobile h2,
  .faq-mobile h2,
  .history-mobile h2 {
    font-size: 24px;
  }
  .how-it-works-mobile,
  .faq-mobile,
  .history-mobile {
    background-color: #ffffff;
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #cfcfcf;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
    margin: 60px 20px 20px 20px;
  }
  .how-it-works,
  .how-it-works-mobile {
    margin-top: 0;
  }
  .how-it-works-mobile h2,
  .faq-mobile h2,
  .history-mobile h2 {
    font-size: 24px;
  }
  .how-it-works .section-heading,
  .faq .section-heading,
  .history-container .section-heading {
    display: none;
  }
  .special-notes-sec .section-heading {
    margin-bottom: 15px;
  }
  .search-section-heading {
    width: unset;
  }
  .details-head-rht {
    margin-top: 60px;
    padding: 10px 20px 20px;
  }
  .details-head-rht > .price-box:nth-child(2) {
    border: 0;
  }
  .details-head-rht > .price-box:nth-child(7) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
  }
  .details-head-rht > .price-box:nth-child(7) > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .details-head-rht .details-book-now {
    display: none;
  }
  .details-head-rht .price-box:nth-child(8) > div:first-child {
    margin-bottom: 30px;
  }
  .samagri-outer-container {
    height: 100%;
    width: 100%;
    display: none;
    position: fixed;
    z-index: 9999;
    top: 0;
    right: 0;
    background: rgba(255, 255, 255, 1);
    overflow-x: hidden;
    transition: 0.5s;
    padding: 0;
    margin: 0;
    text-align: center;
  }
  .samagri-outer-container {
    text-align: left;
  }
  .samagri-box-inner {
    border: 0;
    border-bottom: 1.5px;
    border-bottom-style: solid;
    border-image: linear-gradient(
        to right,
        #9a7013,
        #d6b677,
        #9e730f,
        #ffe083,
        #c39118,
        #e2b85c
      )
      1;
    margin-top: 20px;
  }
  .samagri-box {
    padding: 0;
    max-height: unset;
  }
  .samagri-header {
    padding: 20px 20px 0 0;
    margin: 20px;
  }
  .samagri-box-inner {
    border: 0;
    border-bottom: 1.5px;
    border-bottom-style: solid;
    border-image: linear-gradient(
        to right,
        #9a7013,
        #d6b677,
        #9e730f,
        #ffe083,
        #c39118,
        #e2b85c
      )
      1;
    margin-top: 20px;
  }
  .samagri-items-container,
  .samagri-what-you-need,
  .samagri-you-can-also-buy {
    padding: 20px;
  }
  .samagri-heading {
    font-size: 16px;
  }
  .gold-star-line {
    display: none;
  }
  .samagri-items-container,
  .samagri-what-you-need,
  .samagri-you-can-also-buy {
    padding: 20px;
  }
  .samagri-items ul li,
  .samagri-what-you-need-items ul li {
    margin-left: 0;
  }
  .you-can-buy-list li .buy-item-price {
    font-size: 14px;
  }
  .you-can-buy-list {
    border-bottom: 1px solid #cfcfcf;
    padding-bottom: 15px;
    padding-right: 10px;
  }
  .you-can-buy-list li {
    padding: 12px 0;
    display: flex;
    border: 0;
  }
  .you-can-buy-list li p {
    font-size: 14px;
  }
  .you-can-buy-list li p:nth-child(1) {
    width: 180px;
    padding-right: 20px;
  }
  .you-can-buy-list li p:nth-child(2) {
    width: 90px;
  }
  .you-can-buy-list li p:nth-child(3) {
    width: 60px;
  }
  .you-can-buy-list li p:nth-child(4) {
    width: 42px;
    text-align: unset;
    margin-left: unset;
  }
  .all-items-checkbox {
    padding: 0 20px 20px;
  }
  .all-items-checkbox .details-book-now {
    width: 100%;
    margin-top: 40px;
  }
  .samagri-box-inner {
    border: 0;
    border-bottom: 1.5px;
    border-bottom-style: solid;
    border-image: linear-gradient(
        to right,
        #9a7013,
        #d6b677,
        #9e730f,
        #ffe083,
        #c39118,
        #e2b85c
      )
      1;
    margin-top: 20px;
  }
  .samagri-items-container,
  .samagri-what-you-need,
  .samagri-you-can-also-buy {
    padding: 20px 10px;
  }
}
@media screen and (max-width: 400px) {
  .procedure-title {
    font-size: 16px !important;
  }
  .book-puja-container {
    margin-top: 0;
  }
  .img-slide img {
    width: 350px;
    height: 200px;
  }
  .details-bottom-sec {
    margin-top: 30px;
    padding: 0;
  }
  .details-bottom-sec p {
    font-size: 12px;
  }
  .contact-us-form-header .section-heading {
    padding: 0;
  }
  .you-can-buy-list li p:nth-child(1) {
    width: 150px;
    padding-right: 15px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .book-puja-container {
    position: absolute;
  }
}
@media screen and (max-width: 767px) and (min-width: 480px) {
  .centertext .book-puja-container .book-puja-container {
    display: block;
  }
}
@media screen and (max-width: 1920px) and (min-width: 1600px) {
  .insight-slider-container {
    max-width: 1440px;
  }
  .life-based-pujas-slider-container,
  .occasion-based-pujas-slider-container {
    max-width: 1440px;
    margin-left: auto;
  }
}
@media screen and (max-width: 1199px) {
  .life-based-pujas-slider-container,
  .occasion-based-pujas-slider-container {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}
@media screen and (max-width: 1024px) {
  .insight-slider-container,
  .life-based-pujas-slider-container,
  .occasion-based-pujas-slider-container {
    max-width: 854px;
  }
}
@media screen and (max-width: 991px) {
  .life-based-pujas-slider-container,
  .occasion-based-pujas-slider-container {
    margin: 0 40px;
  }
}
@media screen and (max-width: 768px) {
  .life-based-pujas-slider-container,
  .occasion-based-pujas-slider-container {
    padding-left: 15px;
  }
  .insight-slider-container,
  .life-based-pujas-slider-container,
  .occasion-based-pujas-slider-container {
    max-width: 600px;
  }
}
@media screen and (max-width: 720px) and (min-width: 600px) {
  .insight-slider-container,
  .life-based-pujas-slider-container,
  .occasion-based-pujas-slider-container {
    max-width: 450px;
  }
}
@media screen and (max-width: 600px) and (min-width: 480px) {
  .insight-slider-container,
  .life-based-pujas-slider-container,
  .occasion-based-pujas-slider-container {
    max-width: 380px;
  }
  .insight-slider-container {
    padding-left: 20px;
  }
}
@media screen and (max-width: 479px) {
  .insight-slider-container,
  .life-based-pujas-slider-container,
  .occasion-based-pujas-slider-container {
    max-width: 300px;
  }
  .life-based-pujas-slider-container {
    margin: 0 40px;
  }
  .occasion-based-pujas-slider-container {
    margin: 0 40px;
  }
  .home-page-slider-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 400px) {
  .insight-slider-container {
    max-width: 250px;
  }
}
@media screen and (max-width: 375px) {
  .life-based-pujas-slider-container,
  .occasion-based-pujas-slider-container {
    max-width: 280px;
  }
}
