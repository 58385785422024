@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PlayfairDisplayMedium.ttf");
  font-family: "Playfair Display";
  font-weight: 500;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PlayfairDisplayRomanRegular.ttf");
  font-family: "Playfair Display";
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/QuicksandMedium.ttf");
  font-family: "Quicksand";
  font-weight: 500;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PlayfairDisplayRegular.ttf");
  font-family: "Playfair Display";
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/QuicksandBold.ttf");
  font-family: "Quicksand";
  font-weight: 700;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PlayfairDisplayRomanMedium.ttf");
  font-family: "Playfair Display";
  font-weight: 500;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/QuicksandSemiBold.ttf");
  font-family: "Quicksand";
  font-weight: 600;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/QuicksandRegular.ttf");
  font-family: "Quicksand";
  font-weight: 400;
}
