.contacticon {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 99;
    width: 80px;
  }
  
  .whatsup{
    max-width: 100%;
    height: auto;
  }
  