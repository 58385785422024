.karishye-form {
    padding: 80px 0 100px;
}
.contact-button {
    color: #62324E;
    font-size: 44px;
    font-weight: 500;
    font-family: "Fraternite" !important;
    border: none !important;
    border-color: #fff;
    line-height: normal;
    padding: 1rem 1.25rem;
    text-align: center;
}
.accordion-body {
    width: 100%;
    border: none;
    display: inline-block;
    padding: 18px 36px !important;
}
.form-group.max-width-r {
    width: 100%;
    float: right;
    max-width: 48%;
}
.form-group {
    width: 100%;
    display: inline-block;
}
.mb-3 {
    margin-bottom: 1rem !important;
}
.labeltitle {
    font-size: 17px;
    line-height: normal;
    padding-bottom: 10px;
    color: #000;
    font-weight: 500;
    font-family: "Quicksand", sans-serif !important;
}
span.erroricon {
    display: none;
}
label {
    display: inline-block;
}
.btn-box {
    width: 100%;
    display: inline-block;
}
.bookingsubmitbut {
    border: none;
    color: #fff;
    font-size: 17px;
    background: #62324E;
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    padding: 12px 40px;
    margin-top: 20px;
    margin-bottom: 20px;  //fixed button alignment issue
    &:hover{
        background-color: #761E54;
    }   //changed the buttion styling
}
.form-select {
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
}
.int-fill {
    border: 1px solid #D8D8D8;
    font-size: 18px;
    font-family: "Quicksand", sans-serif !important;
    border-radius: 0;
    padding: 10px;
    height: auto;
    font-weight: 500;
    color: #736A6F !important;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
    appearance: none !important;
    background-color: transparent;
}
#myprofile-aftersubmit .modal-dialog.modal-dialog-centered {
    max-width: 462px;
    color: green;
    h5{
        text-align: center;
    }
}
.blur {
    filter: blur(5px);
    pointer-events: none;
    user-select: none;
  }
  
  .modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1050;
  }
  
  .modal-container .modal {
    z-index: 1060;
  }
  @media screen and (max-width: 480px) {
    .int-fill, .labeltitle {
        font-size: 12px;
    }
  }
  @media screen and (max-width: 767px) {
    .form-group.max-width-r, .form-group.max-width-l {
        float: none !important;
        max-width: 100% !important;
    }
  }