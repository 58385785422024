.BookingSection {
  background: #8080800a;
  max-width: 1440px;
  margin: auto;
  padding-top: 8rem;
}
.top-form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.back-details {
  font-style: normal;
  font-family: "Quicksand",serif !important;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #262525;
  cursor: pointer;
}
.back-details img {
  margin-right: 6px;
}

.contact-button {
  color: #62324e;
  font-size: 44px;
  font-weight: 500;
  font-family: "Fraternite",serif !important;
  border: none !important;
  border-color: #fff;
  line-height: normal;
  padding: 1rem 1.25rem;
  text-align: center;
}
.field-mandatory {
  font-family: "Quicksand",serif !important;
}
.outline-info {
  margin-right: 5px;
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.price-detail-sec {
  padding: 25px 30px;
}
.accordion-item {
  background: #fff;
  border: none;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.08);
  margin-bottom: 20px;
}

.form-puja-name-price-sec {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.form-puja-heading {
  font-family: "Fraternite",serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 45px;
  color: #5a1740;
}
.samagri-list-heading {
  display: inline;
  font-family: "Quicksand",serif !important;
  font-size: 20px;
  font-weight: 600;
  color: #5a1740;
}
.form-price-sec {
  margin-left: auto;
  display: flex;
}
.form-p {
  font-family: "Quicksand",serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #262525;
  margin-left: 10px;
  margin-top: 15px;
}

.form-price {
  font-family: "Quicksand",serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #df7900;
  margin-left: 20px;
  margin-top: 15px;
}
.samagri-count {
  font-family: "Quicksand",serif !important;
}
.border-div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-view-details p,
.form-hide-details p {
  font-family: "Quicksand",serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #5a1740;
  margin-right: 6px;
  margin-top: 10px;
}
.selected-puja-bottom-sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.selected-puja-bottom-sec div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-puja-modes div {
  margin-right: 30px;
}

.available-box {
  gap: 0.5rem;
}
.available-box img {
  width: 24px;
}
.viewmoreDeatil {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #262525;
  margin-right: 10px;
  margin-top: 15px;
}
.form-puja-modes div p {
  font-family: "Quicksand",serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #262525;
}
.border-div hr {
  width: 100%;
  border-bottom: 1.5px;
  border-bottom-style: solid;
  border-image: linear-gradient(
      to right,
      #9a7013,
      #d6b677,
      #9e730f,
      #ffe083,
      #c39118,
      #e2b85c
    )
    1;
}
.form-p {
  font-family: "Quicksand",serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #262525;
  margin-left: 10px;
}

.form-samagri-and-price-breakup {
  padding: 25px 0 10px;
  font-family: "Quicksand",serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
  height: 450px;
}
.form-view-details {
  cursor: pointer;
}
.form-hide-details {
  cursor: pointer;
  margin-right: 10px;
  align-items: center;
}
.form-samagri-sec {
  width: 50%;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  overflow: auto;
}
.samagri-heading {
  display: inline;
  font-family: "Quicksand",serif;
  font-size: 26px;
  font-weight: 600;
  color: #5a1740;
}
.samagri-list-sec {
  margin-top: 20px;
}
.samagri-list-sec ul li {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.list-of-samagri li:first-child p:first-child,
.samagri-list-name {
  width: 300px;
  margin-right: 20px;
}
.list-of-samagri li:first-child p:nth-child(2),
.samagri-list-qty {
  width: 120px;
  font-weight: 600;
}
.form-price-header {
  font-family: "Quicksand",serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px !important;
  line-height: 26px;
  color: #262525;
}
.price-box-invoice {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px solid #e0cce6;
  padding: 8px 0;
}
.bookingerrormessage {
  color: red;
  font-weight: 600;
  margin-left: 20px;
}
.samagri-list-price {
  font-weight: 600;
}
.form-price-breakup {
  padding: 0 0 0 50px;
  width: 50%;
  font-family: "Quicksand",serif !important;
}
.price-box-invoice p {
  font-weight: 600;
}
.price-box-invoice div {
  flex-direction: row;
}
.price-box-invoice div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.price-box-invoice > div > .total-incl-tax {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.price,
.floater-price,
.floater-detail-price,
.right-floater-price {
  font-weight: 600;
  font-size: 20px;
  color: #df7900;
  margin-bottom: 0;
}
.accordion-header {
  font-family: "Fraternite",serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 45px;
  color: #62324e;
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.form-spl-notes {
  padding: 30px 30px 50px;
}

.accordion-item {
  background: #fff;
  border: none;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  margin-bottom: 20px;
}

.form-spl-notes h2 {
  padding: 0 !important;
  margin-bottom: 20px;
}

.accordion-body {
  width: 100%;
  border: none;
  display: inline-block;
}
.align-items-center {
  align-items: center !important;
}
.checkinfl {
  width: 100%;
}
.checkinfl li.active {
  background: #62324e;
  color: #fff;
  border-color: #62324e;
}
.checkinfl li img {
  position: relative;
  top: -5px;
}
input.class_puja_mode.hiddenclass {
  visibility: hidden;
  width: 0;
  height: 0;
  font-family: "Fraternite",serif !important;
}
.over_content p {
  font-size: 14px;

  cursor: pointer;
  font-weight: 500;
  line-height: 15px;
  padding: 0;
  color: #000;
  position: relative;
}
.over_content::after {
  border-style: solid;
  border-width: 0 0 21px 21px;
  height: 0;
  width: 0;
  content: "";
  display: inline-block;
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
  bottom: -10px;
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-color: transparent;
  border-bottom-color: #fff;
}
.btn-box {
  width: 100%;
  display: inline-block;
}
.over_content {
  width: 95%;
  position: absolute;
  bottom: 134%;
  display: none;
  font-size: 17px;
  left: 0;
  background: #fff;
  box-shadow: 0px 0px 30px -5px #00000020;
  padding: 3px;
  z-index: 9;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.accordingSection {
  padding: 1rem 1.25rem;
}

.checkinfl li {
  width: 100%;
  max-width: 30%;
  display: inline-block;
  margin: 0px;
  padding: 15px 10px;
  text-align: center;
  background: #fff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border: 1px solid #ccc;
  color: #000;
  font-size: 18px;
  font-family: "Quicksand", sans-serif;
  font-weight: 400;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 13px;
  position: relative;
  img {
    margin-right: 10px;
  }

  &:hover {
    .over_content {
      display: block;
    }
  }
}
.justify-content-start {
  justify-content: flex-start !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.d-flex {
  display: flex !important;
}
.notetag {
  display: inherit;
  margin-left: 0;
  font-family: "Quicksand",serif;
  font-style: italic;
  color: #bb8626;
  font-weight: bold;
  font-size: 11px;
  margin-bottom: 27px;
}
// .int-fill {
//   border: 1px solid #d8d8d8;
//   font-size: 18px;
//   font-family: "Quicksand", sans-serif;
//   border-radius: 0;
//   padding: 10px;
//   height: 7rem;
//   font-weight: 500;
//   color: #736a6f !important;
//   box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
//   appearance: none !important;
//   background-color: transparent;
// }
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.checkself-flild {
  width: 100%;
}

.int-fill-input {
  border: 1px solid #d8d8d8;
  font-size: 18px !important;
  font-family: "Quicksand", sans-serif;
  border-radius: 0;
  padding: 10px;
  height: 3.5rem;
  font-weight: 500;
  color: #736a6f !important;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  appearance: none !important;
  background-color: transparent;
}
.form-group.max-width-r {
  width: 100%;
  float: right;
  max-width: 48%;
}
.labeltitle {
  font-size: 17px;
  line-height: normal;
  padding-bottom: 10px;
  color: #000;
  font-weight: 500;
  display: inline-block;
  font-family: "Quicksand", sans-serif !important;
}
.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;

  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;

  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  option {
    font-size: 18px !important;
    font-weight: 600 !important;
  }
}
.radio-yes,
.radio-no {
  margin-right: 5px;
  border-color: #dcd7ca;
}
.muhurtam-check-div div {
  display: flex;
  gap: 20px;
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
}
select {
  word-wrap: normal;
}
.muhurtam-label {
  font-size: 16px;
  line-height: normal;
  color: #000;
  font-weight: 500;
  font-family: "Quicksand", sans-serif !important;
}

.muhurtam-label input {
  height: 20px;
  width: auto !important;
}

.booking-customer-fields {
  padding: 30px 30px 23px;
}

.muhurtam-check-div p {
  font-size: 18px;
  line-height: normal;
  color: #000;
  font-weight: 600;
  display: flex;
}
span.error,
span.contact_error {
  color: red;
  font-size: 0.8rem;
}
span.erroricon {
  display: none;
}
span.erroricon {
  color: red;
}
.mb-3 {
  margin-bottom: 1rem !important;
}

.form-group.max-width-l {
  width: 100%;
  float: left;
  max-width: 48%;
  clear: both;
}

.form-group {
  width: 100%;
}
.form-group {
  display: inline-block;
}
.flexlabel {
  padding-bottom: 0 !important;
  .linkdetail {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.privacy-policy-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.privacy-policy-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.linkdetail u {
  color: #cd2653 !important;
  font-family: "Quicksand", sans-serif !important;
}
.accordion-body input#agree {
  width: 1.7rem;
  height: 1.7rem;
  display: inline-block;
  margin-right: 0.5rem;
}

.bookingsubmitbut {
  border: none;
  color: #fff !important;
  font-size: 18px;
  background: #62324e;
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
  padding: 12px 40px;
  margin-top: 20px;
  cursor: pointer;
}
.loginsubmit {
  margin-top: 30px;
}
.bookingsubmitbut:hover {
  color: #fff !important;
}
.react-tel-input .form-control {
  width: 525px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
}
.signup-form {
  z-index: 9999;
  background: #000c;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}
.modal.show .modal-dialog {
  transform: none;
}
.modal-dialog {
  max-width: 900px;
  position: relative;
}
.signup-form .modal-content {
  max-width: 790px;
  margin: 0 auto;
}
.signup-form .modal-header {
  padding: 2rem 8rem 0rem;
  border-bottom: 0;
  display: block;
  width: 100%;
}
.signup-form .modal-title {
  font-size: 44px;
  text-align: center;
  color: #62324e;
  border: none;
  font-family: "Playfair Display", serif !important;
}
.signup-form button.btn-close {
  margin: 8px;
}
.form-control {
  height: auto;
}
.modal-dialog .btn-close {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 99;
  border: 0;
  outline: 0;
  padding: 0;
  width: 20px;
  height: 20px;
  display: block;
  color: #000;
  background-size: 100% 100%;
}
.signup-form .modal-body {
  padding: 2rem 8rem;
}
.modal-dialog {
  max-width: 900px !important;
  position: relative !important;
}
.form-group {
  width: 100%;
  display: inline-block;
}
.signup-form .modal-footer {
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.75rem 0 0;
  border: none;
}
button.close-btn-login,
button.close-btn-register {
  border: none;
  color: #000000;
  font-size: 17px;
  background: #ffffff;
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
  padding: 12px 60px 12px 40px;
  box-shadow: 0px 4px 16px rgb(0 0 0 / 8%);
}
.arrow {
  color: white;
}
img {
  max-width: 100%;
  height: auto;
}
.bookingsubmitbut {
  text-decoration: none;
}
.singup {
  font-family: "Playfair Display", serif !important;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  a {
    color: #cd2653 !important;
    font-family: "Playfair Display", serif !important;
    text-decoration: none;
  }
  a:hover {
    color: #0d6efd !important;
  }
}
.phoneInput {
  box-shadow: #212529;
  margin-bottom: 10px;
}
.btn_continue {
  position: relative;
}
.btn_continue {
  border: none;
  color: #fff;
  font-size: 17px;
  background-color: #62324e;
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
  padding: 12px 60px 12px 40px;
  margin-top: 20px;
}
.top-form-header button{
  border: none;
}

.center {
  text-align: center;
}
.margin-top {
  margin-top: 2rem;
}
.form-group {
  width: 100%;
  display: inline-block;
}
.login-form-massage {
  margin-top: 20px;
  color: red;
  text-align: center;
  font-family: "Quicksand", sans-serif !important;
}
@media screen and (max-width: 1440px) {
  .BookingSection {
    padding-left: 7%;
    padding-right: 7%;
  }
  .samagri-list-price {
    margin-right: 10px !important;
  }
}
@media screen and (max-width: 900px) {
  .BookingSection {
    padding-left: 5%;
    padding-right: 5%;
  }
  .contact-button {
    color: #62324e;
    font-size: 12px;
    font-weight: 800;
    font-family: "Fraternite",serif;
    border: none !important;
    border-color: #fff;
    line-height: normal;
    padding: 10px;
    margin-bottom: 0;
  }
  .back-details img {
    margin-right: 4px;
    width: 14px;
  }
  .selected-puja-bottom-sec {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 5px;
    flex-direction: column;
  }
  .viewmoreDeatil {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #262525;
    margin-right: 0;
  }
  .form-puja-heading {
    font-family: "Fraternite",serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 26px;
    color: #5a1740;
  }
  .form-puja-modes div p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  }
  .selected-puja-bottom-sec div {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .form-samagri-and-price-breakup {
    flex-wrap: wrap;
    overflow: scroll;
  }
  .samagri-list-qty {
    font-size: 14px;
    white-space: pre;
  }
  .form-group.max-width-r {
    width: 100%;
    float: right;
    max-width: 100%;
  }
  .form-group.max-width-l {
    width: 100%;
    float: left;
    max-width: 100%;
    clear: both;
  }
  .back-details {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #262525;
  }
  .booking-customer-fields {
    padding: 15px;
  }
  .labeltitle {
    font-size: 14px;
  }
  .samagri-list-heading {
    font-size: 16px;
  }
  .samagri-list-name {
    width: 190px;
    margin-right: 20px;
  }
  .checkinfl li {
    max-width: 100%;
  }
  .accordion-header {
    font-family: "Fraternite",serif;
    font-style: normal;
    font-size: 22px;
    line-height: 45px;
    color: #5a1740;
  }
  .samagri-list-price {
    font-size: 14px;
    white-space: pre;
    margin-left: 10px;
  }
  .samagri-list-name {
    width: 300px;
    font-weight: 600;
    font-size: 14px;
  }
  .list-of-samagri li:first-child p:first-child,
  .samagri-list-name {
    width: 300px;
    font-weight: 600;
    margin-right: 0px;
  }
  .price-box p {
    font-weight: 600;
    font-size: 14px;
  }
  .form-price-breakup {
    padding: 0 0 0 10px;
    width: 100%;
  }
  .form-price-header {
    font-family: "Quicksand",serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    color: #262525;
  }
  .form-samagri-sec {
    width: 100%;
    padding-right: 10px;
  }
  .samagri-heading {
    display: inline;
    font-family: "Quicksand",serif;
    font-size: 14px;
  }
  .form-hide-details p {
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    margin-right: 10px;
  }
  .form-puja-modes div {
    margin-right: 10px;
  }
  .form-price {
    font-family: "Quicksand",serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }
  .form-p {
    font-family: "Quicksand",serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #262525;
    margin-left: 10px;
  }
  .price-detail-sec {
    padding: 15px 15px;
  }
  .back-details {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #262525;
  }
  .field-mandatory {
    font-size: 15px;
    font-weight: 400;
    font-style: normal;
    line-height: 19px;
    color: #262525;
    margin-bottom: 20px;
  }
  .contact-button {
    color: #62324e;
    font-size: 30px;
    font-weight: 500;
    font-family: "Fraternite",serif;
    border: none !important;
    border-color: #fff;
    line-height: normal;
    padding: 10px;
    margin-bottom: 0;
  }
  .muhurtam-label {
    font-size: 12px;
  }
  .muhurtam-check-div p {
    font-size: 12px;
  }
}
@media screen and (max-width: 767px) {
  .top-form-header {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .top-form-header h2 {
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .labeltitle {
    font-size: 12px;
  }
  .labeltitle a {
    margin: 0px !important;
  }
}
@media screen and (max-width: 1140px) {
  .price-box {
    padding: 14px 0;
  }
  .checkinfl li {
    max-width: 100%;
  }
  .selected-puja-bottom-sec div {
    display: block;
  }
  .form-samagri-count {
    display: flex !important;
  }
}
@media screen and (max-width: 1024px) {
  .form-samagri-sec {
    padding: 0 40px 0 0;
  }
  .form-price-breakup {
    padding: 0 0 0 50px;
  }
}
@media screen and (max-width: 991px) {
  .samagri-list {
    columns: 1;
  }
  .form-puja-modes {
    margin-right: 15px;
  }
  .form-puja-modes div {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
  }
  .form-puja-modes div p {
    font-size: 14px;
  }
  .form-samagri-and-price-breakup {
    flex-direction: column;
    height: unset;
  }
  .form-samagri-sec {
    width: 100%;
    border-right: 0;
    padding: 0;
  }
  .form-price-breakup {
    width: 100%;
    padding: 0;
    margin-top: 20px;
  }
  .list-of-samagri li:first-child p:first-child,
  .samagri-list-name {
    width: 350px;
  }
}
@media screen and (max-width: 768px) {
  .top-form-header {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .top-form-header h2 {
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .field-mandatory {
    margin-bottom: 20px;
  }
  .selected-puja-bottom-sec div:last-child {
    margin-left: 0;
  }
  .form-samagri-and-price-breakup {
    padding-bottom: 0;
  }
  .form-price-breakup {
    margin-top: 0;
  }
  .selected-puja-bottom-sec {
    flex-direction: column;
  }
  .selected-puja-bottom-sec div {
    width: 100%;
    margin-bottom: 10px;
  }
  .form-puja-modes {
    justify-content: space-between;
    margin-right: 0;
    border-right: 0;
    border-bottom: 1px solid #cfcfcf;
  }
  .form-view-details,
  .form-hide-details {
    margin-top: 10px;
  }
  .form-samagri-and-price-breakup {
    margin-top: 0;
    font-size: 14px;
  }
  .form-group.max-width-r {
    width: 100%;
    float: right;
    max-width: 100% !important;
  }
}
@media screen and (max-width: 479px) {
  .price-box {
    padding-right: 0;
  }
  .all-items-checkbox .details-book-now {
    width: 100%;
    margin-top: 40px;
  }
  .price-box .details-book-now-mobile {
    display: flex;
    margin-top: 15px;
  }
  .price-detail-sec {
    padding: 15px;
  }
  .form-puja-heading {
    font-size: 24px;         //changed the font size of header
  }
  .form-p {
    font-size: 14px;
  }
  .selected-puja-bottom-sec {
    flex-direction: column;
  }
  .selected-puja-bottom-sec div {
    width: 100%;
    margin-bottom: 10px;
  }
  .form-puja-modes {
    justify-content: space-between;
    margin-right: 0;
    border-right: 0;
    border-bottom: 1px solid #cfcfcf;
  }
  .form-view-details,
  .form-hide-details {
    margin-top: 10px;
  }
  .form-samagri-and-price-breakup {
    margin-top: 0;
    font-size: 14px;
  }
  .form-price-sec {
    display: none;
  }
  .accordion-item h2.accordion-header {
    padding: 1rem 12px 12px;
  }
  .btn-box {
    margin-bottom: 20px;
  }
  .muhurtam-label {
    font-size: 12px;
  }
  .muhurtam-check-and-date {
    margin-bottom: 10px !important;
  }
  .muhurtam-check-div div {
    font-size: 14px;
  }
}
@media screen and (max-width: 420px) {
  .price-detail-sec {
    padding: 15px;
  }
  .contact-button {
    font-size: 30px !important;
  }
  .form-select {
    width: 50%;
  }
  .list-of-samagri li:first-child p:first-child,
  .samagri-list-name {
    width: 190px;
  }
  .list-of-samagri li:first-child p:nth-child(2),
  .samagri-list-qty {
    width: 80px;
    margin-left: 30px;
  }
  .list-of-samagri li:first-child p:nth-child(3),
  .samagri-list-price {
    width: 60px;
    text-align: right;
  }
}
@media screen and (min-width:1280px) and (max-width: 1350px) {
  .list-of-samagri li:first-child p:first-child, .samagri-list-name {
    width: 200px;
    margin-right: 20px;
}
  .list-of-samagri li:first-child p:nth-child(2), .samagri-list-qty {
    width: 120px;
}
  .list-of-samagri li:first-child p:nth-child(3), .samagri-list-price {
    width: 100px;
    text-align: right;
}
}
@media screen and (max-width: 360px) {
  .list-of-samagri li:first-child p:first-child, .samagri-list-name {
    width: 190px;
    margin-right: 20px;
}
  .list-of-samagri li:first-child p:nth-child(2), .samagri-list-qty {
    width: 80px;
}
  .list-of-samagri li:first-child p:nth-child(3), .samagri-list-price {
    width: 90px;
    text-align: right;
}
.form-select option {
  font-size: 10px !important;
}
}