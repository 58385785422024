.SectionTopBookPooja {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 756.266px;
  padding-top: 90px;
  padding-bottom: 6rem;

  border-bottom: 0.5px solid #e7e7e7;
  .InnerContent {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1440px;
    width: 100%;
  }
  h1 {
    font-family: "Nunito Sans", sans-serif !important;
    color: #5a1740;
    font-style: normal;
    font-weight: 600;
    padding: 0 0 33px 0;
    max-width: 608px;
    font-size: 75px;
    margin-top: 90px;
  }
  .label1 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 210px;
    height: 51.631px;
    flex-shrink: 0;
    color: #fff;
    text-align: center;
    font-family: "Nunito Sans", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-right: 22px;
    background-color: #542e42;
    margin-bottom: 0;
    cursor: pointer;
    transition: all 0.5s linear;
    &:hover {
      transition: all 0.5s linear;
      transform: scale(1.05);
    }
  }
  .labelSection {
    display: flex;
    align-items: center;
    margin-top: 3rem;
  }
  .label2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 210px;
    height: 51.631px;
    flex-shrink: 0;
    color: #fff;
    text-align: center;
    font-family: "Nunito Sans", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-right: 13px;
    background-color: #d5ad54;
    margin-bottom: 0;
    cursor: pointer;
    transition: all 0.5s linear;
    &:hover {
      transition: all 0.5s linear;
      transform: scale(1.05);
    }
  }
}
.book-puja-container {
  .bookpujasec {
    width: 682px;
    max-width: 682px;
    background: #ffffff;
    border: 1px solid #cfcfcf;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
    padding: 24px 45px;
    height: 370px;
    position: relative;
  }
  .bookpujasec-header {
    display: none;
    font-weight: 500;
    font-size: 16px;
    font-family: "Playfair Display",serif !important;
    color: #5a1740;
  }
  .bookpujasec-header-line {
    display: none;
  }
  .pujamodelabel {
    font-weight: 500;
    font-size: 16px;
    color: #262525;
    padding-top: 20px;
    font-family: "Playfair Display", serif !important;
  }
  .pujamodesec {
    display: flex;
    padding-top: 11px;
    margin-bottom: 12px;
  }
  .pujasec {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 48px;
  }
  img {
    max-width: 100%;
    margin-right: 5px;
    height: auto;
  }
  .in-person-icon-active,
  .online-icon-active,
  .onbehalf-icon-active {
    display: block;
  }
  .pujasec-label {
    line-height: 38px;
    padding-left: 10px;
    font-weight: 500;
    font-size: 16px;
    color: #262525;
    font-family: "Quicksand", sans-serif !important;
  }
  .pujasec-mbl {
    margin-right: 0px !important;
  }
  .pujasec-behalf {
    display: none;
  }
  .pujaseckmlabel {
    font-weight: 600;
    font-size: 14px;
    color: #5a1740;
    font-family: "Quicksand", sans-serif !important;
    max-width: 110px;
    line-height: 38px;
    display: block;
    cursor: default;
  }
  .know-more-border {
    padding-left: 10px;
    border-left: 1px solid #a7a7a7;
  }
  .pujasec-behalf .hover-content a {
    display: none;
    width: 40%;
    position: absolute;
    top: 1.6%;
    left: 59.8%;
    background: #fff;
    box-shadow: 0px 0px 30px -5px #00000020;
    z-index: 9;
    font-size: 12px;
    font-family: "Quicksand", sans-serif !important;
    cursor: pointer;
    font-weight: 500;
    line-height: 15px;
    padding: 0;
    color: #000 !important;
  }

  .pujasec-behalf:hover .hover-content a {
    display: block;
  }

  .searchable ul li:hover {
    background-color: #f1e3f6;
  }

  #search-puja-input1 {
    font-family: "Quicksand", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px !important;
    line-height: 20px;
    color: #262525 !important;
    background-image: url(../../public/assets/images/dropdown-arrow.svg);
    background-repeat: no-repeat;
    background-position-x: 97%;
  }
  .pujadisableenable {
    padding: 13px 15px;
    border: 1px solid #d8d8d8;
    font-size: 18px;
    font-family: "Quicksand", sans-serif;
    border-radius: 0;
    height: auto;
    font-weight: 500;
    color: #736a6f !important;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
    appearance: none !important;
    background-color: transparent;
  }
  .searchable {
    margin: 12px 0 30px;
  }

  .searchable ul {
    display: none;
    list-style-type: none;
    background-color: #fff;
    border-radius: 0 0 5px 5px;
    border: 1px solid #cfcfcf;
    border-top: none;
    max-height: 240px;
    margin: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0;
    position: absolute;
    z-index: 100;
    width: 100%;
  }
  .searchable ul li {
    padding: 10px 25px;
    background-color: #fff;
    cursor: pointer;
    color: #000;
    width: 100%;
    font-family: "Playfair Display", serif !important;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
  }
  .search-pujas-list li:nth-last-child(2) {
    padding: 0;
    position: sticky;
    bottom: 0;
    padding-bottom: 10px;
  }
  .not-found-item {
    border: 0.5px solid #9a7013;
    text-align: center;
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #262525;
    margin: 14px 20px 0px 20px;
    font-family: "Playfair Display", serif !important;
  }
 .search-pujas-list li:last-child {
    display: none !important;
  }
  .bookpujasec .bookbtn {
    margin-right: -3px;
    cursor: pointer;
    position: absolute;
    right: 7%;
    margin-top: 6px;
  }
  a.ritualbut {
    display: block;
    border: 1px solid #5a1740;
    line-height: 40px;
    padding: 0 36px;
    text-transform: capitalize;
    color: #fff;
    font-family: "Quicksand", sans-serif !important;
    font-weight: 500;
    float: right;
    margin-right: 20px;
    background: #5a1740;
  }
}
.home-viewmore {
  text-decoration: none;
}

.home-page-viewmore {
  border: 1px solid #5a1740;
  color: #5a1740;
  width: fit-content;
  padding: 8px 35px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Quicksand", sans-serif !important;
  margin: 30px auto 0;
}
.slick-prev:before {
  content: url(../../public/assets/images/image-slide-left.png) !important;
}
.slick-next:before {
  content: url(../../public/assets/images/image-slide-right.png) !important;
}
.fstcontsec {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  background-size: 100% auto;
  object-fit: cover;
}
.fstcontsec h1 {
  font-family: "Nunito Sans", sans-serif !important;
  color: #5a1740;
  font-style: normal;
  font-weight: 600;
  padding: 0 0 33px 0;
  max-width: 608px;
  font-size: 80px;
  margin-top: 80px;
}
.first-sec-img {
  margin-top: 53px;
}
.puja-image {
  width: 100%;
  height: 160px;
  border: 0.5px solid #5a1740;
}
.puja-book-button {
  border: 1px solid #5a1740;
  color: #5a1740;
  background-color: #fff;
  width: 130px;
  height: 40px;
}

.puja-book-button a {
  color: #5a1740;
}
.puja-title {
  font-family: "Playfair Display",serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #262525;
  margin: 20px 0 10px;
  min-height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical
}

.bottom-price-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
}
.bottom-price-btn .puja-price {
  font-size: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.life-based-card img,
.occasion-based-card img{
  max-width: 100%;
  height: 160px;
}
.slick-initialized .slick-slide {
  padding: 15px 40px 20px 2px;
}
.puja-desc {
  font-size: 14px;
  font-weight: 500;
  min-height: 72px;
  line-height: 24px;
  letter-spacing: 0.01em;
  margin-bottom: 20px;
  color: #262525;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.gold-line{
  width: 100% !important;
  height:auto !important;
}
.puja-desc,
.puja-book-button {
  font-family: "Quicksand",sans-serif;
}
@media screen and (max-width: 1600px) {
  .life-event-based-puja-websec {
    margin-top: -120px;
  }
  .fstcontsec h1 {
      font-size: 80px;
      line-height: 86px;
      max-width: 608px;
      width: 100%;
  }
}
img {
  max-width: 100%;
  height: auto;
}
.liveEvent {
  margin-top: 3rem;
  .eventInnerContent {
    max-width: 1440px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  h1 {
    font-family: "Nunito Sans",sans-serif;

    font-weight: 800;

    font-size: 70px;
    line-height: 96px;
    color: #5a1740;
  }
  .liveEventcards {
    height: 600px;
    width: 1260px;
  }
  .list {
    background: #ffffff;
    border: 1px solid #cfcfcf;
    width: 370px;
    margin-right: 20px;
    margin-bottom: 3rem;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
    cursor: pointer;
    transition: all 0.5s linear;
    &:hover {
      transition: all 0.5s linear;
      transform: scale(1.03);
    }
  }
  .imageSection {
    width: 100%;
    border: 0.5px solid #5a1740;
  }
  h2 {
    font-family: "Playfair Display",sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #262525;
    margin: 20px 0 10px;
    min-height: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  p {
    font-size: 14px;
    font-family: "Quicksand",sans-serif  !important;
    font-weight: 500;
    min-height: 72px;
    line-height: 24px;
    letter-spacing: 0.01em;
    margin-bottom: 20px !important;
    color: #262525;
    overflow: hidden !important;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .KnowMorebutton {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
  }
  .liveEventButton {
    border: 1px solid #5a1740;
    color: #5a1740;
    background-color: #fff;
    width: 130px;
    height: 40px;
    font-family: "Quicksand",sans-serif  !important;
  }
  .listCard {
    padding: 30px;
  }
}

.remedial-issues-container {
  background: #ffffff;
  border: 1px solid #df7900;
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 80px 10px;
  padding: 10px;
}
.remedial-issues-left {
  width: 30%;
  img {
    max-width: 100%;
    height: auto;
  }
}
.cardImage {
  border: 0.5px solid #5a1740;
}
.remedial-issues-right {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  padding-left: 20px;
}
.remedial-issues-right h3 {
  font-family: "Playfair Display",sans-serif  !important;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 32px;
  color: #5a1740;
}
.home-page-slider-container-viewmore {
  border: 1px solid #5a1740;
  color: #5a1740;
  width: fit-content;
  padding: 8px 35px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Quicksand",sans-serif ;
  margin: 30px auto 0;
}

.home-page-slider-container-main {
  bottom: -110px;
}
.remedial-issues-right p {
  font-family: "Quicksand",sans-serif  !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #262525;
}
.contact-us-button {
  background: #5a1740;
  border: 1px solid #5a1740;
  font-family: "Quicksand",sans-serif  !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 33px;
  &:hover {
    transform: scale(1.03);
  }
}

.occasion-tab {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
  cursor: pointer;
  &:hover {
    transform: scale(1.03);
  }
}
.occasion-tab img {
  height: 40px;
  width: 40px;
}
.occasion-title {
  font-family: "Quicksand",sans-serif  !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 10px 0;
}
.occ-active {
  color: #5a1740;
  border-bottom: 4px solid #5a1740;
}

.occasion-tabs-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #262525;
  width: 100%;
  margin-top: 30px;
}

.occasion-container {
  margin: 100px 0 40px;
  max-width: 1440px;
}
.see-more-pujas {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  margin: auto;
  max-width: 1440px;
  margin-top: 30px;
  margin-right: 30px;
  .textpooja {
    font-size: 24px;
    font-weight: 500;
    font-family: "Playfair Display", serif !important;
  }
}
.see-more-pujas p {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 0;
}
.view-all-pujas {
  background-color: #5a1740;
  border: 1px solid #5a1740;
  font-family: "Quicksand",sans-serif  !important;
  margin-left: 30px;
  width: 150px;
  height: 42px;
  font-weight: normal;
  &:hover {
    transform: scale(1.04);
  }
}
.viewallbut {
  font-family: "Quicksand",sans-serif  !important;
  font-weight: 500;
}
.see-more-pujas a {
  color: white !important;
  text-decoration: none !important;
  &:hover {
    color: white !important;
  }
}

.contact-us-button a {
  color: white !important;
  text-decoration: none !important;
  &:hover {
    color: white !important;
  }
}

.How-its-work-container-mblsec {
  background-repeat: no-repeat;
  background-position: center 35px;
  background-size: contain;
  margin: auto;
  max-width: 1440px;
  margin-top: 30px;
}

.how-it-works-container {
  display: flex;
  align-items: center;
  margin: 20px 0 60px;
}
.how-it-works-sec-card {
  padding: 0px 40px 40px 40px;
  background: #ffffff;
  border: 1px solid #cfcfcf;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 380px;
  height: 100%;
  min-height: 335px;
}
.how-it-works-sec-card img {
  width: 165px;
  height: 165px;
}
.how-it-works-sec-card p {
  font-size: 16px;
  line-height: 30px;
  color: #262525;
  font-family: "Playfair Display",sans-serif  !important;
  letter-spacing: 0.01em;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.frthcontsec {
  width: 100%;
  overflow: hidden;
  padding: 0;
  margin: auto;
  max-width: 1440px;
}
.slick-arrow.slick-prev {
	left: -72px;
}
.slick-arrow.slick-next {
	right: -34px;
}
.frthbtmcontsec {
  width: 100%;
  overflow: hidden;
  padding: 40px 0 0 0;
  background-size: 100% auto;
}
.frthbtmcontsec h4 {
  text-align: center;
  font-family: "Nunito Sans",sans-serif ;
  color: #5a1740;
  font-weight: 800;
  font-style: normal;
  font-size: 90px;
  line-height: 96px;
  padding: 0 0 50px 0;
}
.videosec video {
  width: 100%;
  height: 100% !important;
  border: 1px solid #df7900;
}
.videosec {
  max-width: 1045px;
  height: 587px;
  margin: 0 auto;
  background: #fff;
  overflow: hidden;
}
.frthbtmcontsec h4 {
  font-size: 70px;
  line-height: 65px;
}
.life-based-pujas-slider-container .slick-dots,
.occasion-based-pujas-slider-container .slick-dots {
  display: none !important;
}
.book-puja-container .searchable ul li:nth-last-child(1) {
  padding: 0;
  position: sticky;
  bottom: 0;
  padding-bottom: 10px;
}
.insight-slider-container {
  display: flex;
  position: relative;
  margin: auto;
  max-width: 900px;
}
.insight-slider-dots {
  position: relative;
  z-index: 1;
}
.life-based-mobile-slider-tabs {
  display: none;
  justify-content: center;
  align-items: center;
  margin: 0 50px;
}
.home-page-slider-container-viewmore {
  border: 1px solid #5a1740;
  color: #5a1740;
  width: fit-content;
  padding: 8px 35px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Quicksand",sans-serif ;
  margin: 30px auto 0;
}
.not-found-container {
  margin: 50px 0px 80px;
  border-bottom: 1px solid #000;
  display: none;
  justify-content: space-between;
  align-items: center;
  padding: 0 44px 10px 0;
  max-width: 682px;
  width: 100%;
}
.All__pujas-container .not-found-container {
  margin: 50px 15px 0;
  max-width: 760px;
}
.second-sec {
  background-size: 100% auto;
}
button.accordion-button, .mybookings-bosy-second h2, .mybookings-status h2{
	font-family: 'Fraternite',sans-serif  !important;
}
.mybookings-status {
  padding: 0;
}
.life-event-puja-status-icon {
  border: none;
}
.mybookings-status h2 {
	font-weight: 400;
    font-size: 32px;
    line-height: 31px;
    color: #62324e;
    margin-bottom: 32px;
	padding: 1rem 2rem 0;
    font-family: 'Nunito Sans',sans-serif ;
}
.life-event-puja-status-icon {
  border: none;
}
.life-event-puja-status h2 {
  padding: 0;
  font-size: 70px;
  color: #5A1740;
}
.slick-arrow.slick-prev {
  left: -4rem;
  transform: translate(100%, -50%);
}
.mybookings-status-wrapper {
  display: flex;
  align-items: center;
  text-align: center;
}
.home-headings {
  font-family: 'Nunito Sans',sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 70px;
  line-height: 96px;
  color: #5a1740;
  padding: 0 !important;
}
.home-page-slider-container-viewmore {
  border: 1px solid #5a1740;
  color: #5a1740;
  width: fit-content;
  padding: 8px 35px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Quicksand",sans-serif;
  margin: 30px auto 0;
}
.slick-arrow.slick-prev {
	left: -72px;
}
.home-page-slider-container-main .slick-dots {
  bottom: -110px;
}
.second-sec {
  background-size: 100% auto;
}
.insight-slider-container .slick-arrow.slick-prev {
  left: -44px;
}
.slick-next,
.slick-prev {
  top: 45%;
}
.slick-arrow.slick-hidden {
  display: none;
}
.slick-arrow.slick-prev {
  left: -76px;
}
.slick-arrow.slick-next {
  right: -25px;
}
.slick-arrow {
position: absolute;
z-index: 1;
top: 50%;
width: 2rem;
border: 0px;
padding: 0px;
background-color: transparent;
cursor: pointer;	
color: #000;
outline: 0;
}
.occasion-based-card {
  margin-right: 40px;
  padding: 30px;
  border: 1px solid #c4c4c4;
  background-color: #FFFFFF;
  cursor: pointer;
}
.slick-arrow svg {
width: 50%;
}
.slick-arrow i {
  font-size: 32px;
}
.slick-arrow.slick-prev {
  left: -4rem;
  transform: translate(100%, -50%);
}
.slick-arrow.slick-next {
  right: -1rem;
  transform: translate(-100%, -50%);
}
.puja-image {
  height: 150px !important;
}
.occasion-based-card{
  width:367px;
}
.otherOccasionalCards{
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 789px) {
  .occasion-based-pujas-slider-container {
    display: none !important;
  }
}
@media only screen and (max-width: 375px) { 
	.occation-based-header {
		margin-left: 13px;
	}
	.slick-arrow.slick-prev  {
		left: -62px;
	}
	.slick-arrow.slick-next {
		right: -45px;
	}
}
@media screen and (max-width: 1330px) and (min-width: 1281px) {
  .life-event-based-puja-websec {
    margin-top: 30px;
  }
}

@media screen and (max-width: 1440px) {
  .life-event-based-puja-websec {
    margin-top: 0;
  }
  .SectionTopBookPooja {
    padding-left: 7%;
    padding-right: 7%;
  }
  .liveEvent {
    padding-left: 7%;
    padding-right: 7%;
  }
  .remedial-issues-container {
    margin-left: 7%;
    margin-right: 7%;
  }
}

.fifthcontsec {
  width: 100%;
  overflow: hidden;
  padding: 40px 0;
  background-size: 100% auto;
}
.col-lg-6 {
  flex: 0 0 auto;
}
.fifthcontsec p {
  font-family: "Playfair Display", serif !important;
  font-weight: 500;
  font-style: normal;
  font-size: 36px;
  color: #5a1740;
  background: url("../../public/assets/images/golden-quote.png") no-repeat left
    top;
  padding: 0 0 0 114px;
  line-height: 50px;
  background-size: 60px auto;
  padding-left: 80px;
  margin-top: 100px;
  width: 100%;
}
.containers {
  display: flex;
  align-items: flex-end;
}
.life-based-card {
  padding: 30px;
  margin-right: 40px;
  background: #ffffff;
  border: 1px solid #cfcfcf;
  cursor: pointer;
}
.home-page-sections {
  max-width: 1366px;
  margin: 0 auto;
}
@media screen and (max-width: 1600px) {
  .bookpujasec {
    display: block;
  }
}
@media screen and (max-width: 1350px) {
  .fifthcontsec {
    img {
      width: 100%;
      margin-top: 50px;
    }
  }
  .fifthcontsec p {
    font-size: 22px;
    line-height: 46px;
    background-size: 60px auto;
    padding-left: 0px;
    margin-top: 6rem;
    padding: 50px;
  }
  .fifthcontsec {
    padding-left: 7%;
    padding-right: 7%;
  }
  .How-its-work-container-mblsec {
    padding-left: 7%;
    padding-right: 7%;
  }
  .occasion-container {
    padding-left: 7%;
    padding-right: 7%;
  }

  .remedial-issues-container {
    padding-right: 40px;
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 29px;
      letter-spacing: 0.01em;
      color: #262525;
    }
  }
  .SectionTopBookPooja {
    h1 {
      color: #542e42;
      font-family: "Nunito Sans", sans-serif;
      font-size: 56px;
      font-style: normal;
      font-weight: 600;
      line-height: 82px;
    }
  }
}
@media screen and (max-width: 900px) {
  .fifthcontsec p {
    font-size: 24px;
    line-height: 42px;
    background-size: 60px auto;
    padding-left: 0;
    margin-top: 1rem;
  }
  .fifthcontsec {
    padding-left: 5%;
    padding-right: 5%;
    img {
      width: 100%;
    }
  }
  .How-its-work-container-mblsec {
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 56px;
  }
  .frthbtmcontsec h4 {
    font-size: 25px;
    line-height: 9px;
  }
  .videosec {
    max-width: 1045px;
    height: 250px;
    margin: 0 auto;
  }
  .hiw-arrow {
    display: none;
  }
  .how-it-works-sec-card {
    max-width: 100%;
    margin-bottom: 1.5rem;
  }
  .how-it-works-container {
    flex-wrap: wrap;
  }
  .how-it-works-sec-card p {
    font-size: 17px;
  }
  .see-more-pujas {
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 3px;
    .textpooja {
      font-size: 13px;
    }
  }
  .view-all-pujas {
    background-color: #5a1740;
    border: 1px solid #5a1740;
    margin-left: 30px;
    height: 38px;
    font-size: 12px;
    font-weight: 700;
  }
  .occasion-container {
    padding-left: 5%;
    padding-right: 5%;
  }
  .occasion-tabs-container {
    overflow: scroll;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #262525;
    width: 100%;
    margin: auto;
    max-width: 1440px;
    margin-top: 30px;
  }
  .occasion-tab img {
    height: 40px;
    width: 40px;
  }
  .occasion-title {
    white-space: nowrap;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #262525;
    text-align: center;
    margin-top: 10px;
    font-family: "Quicksand",sans-serif;
  }
  .remedial-issues-left {
    width: 100%;
  }
  .remedial-issues-right h3 {
    font-family: "Playfair Display",sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
    color: #5a1740;
  }

  .remedial-issues-right {
    padding-left: 0;
  }
  .remedial-issues-container {
    margin-left: 5%;
    margin-right: 5%;
    padding-right: 10px;
    padding-bottom: 18px;
    display: block;
    p {
      font-style: normal;
      font-size: 15px;
      line-height: 24px;

      letter-spacing: 0.01em;
      color: #262525;
    }
  }
  .liveEvent {
    padding-left: 10%;
    padding-right: 5%;
    h1 {
      font-size: 50px;
      margin-top: 150px;
      line-height: 75px;
      margin-left: 10px;
    }
    .liveEvent .liveEventcards {
      gap: 0;
      margin-top: 2rem;
    }
    .list {
      border: 1px solid #e7e7e7;
      width: 100%;
      margin-bottom: 3rem;
      cursor: pointer;
      transition: all 0.5s linear;
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }

  .SectionTopBookPooja {
    padding-left: 5%;
    padding-right: 5%;
    position: relative;
    height: 90vh;
    padding-bottom: 0;
    .label2 {
      width: 150px;
      height: 47.631px;
      margin-right: 0;
      font-size: 14px;
    }
    .InnerContent {
      justify-content: center;
    }
    .labelSection {
      width: 100%;
      justify-content: center;
    }
    .label1 {
      width: 150px;
      height: 47.631px;

      font-size: 14px;
    }
    h1 {
      font-size: 54px;
      text-align: center;
      line-height: 64px;
      margin-top: 150px;
    }
  }

  .leftSection {
    position: relative;
    z-index: 2;
  }
}
@media screen and (max-width: 400px) {
  .remedial-issues-container {
    padding: 18px;
  }
  .list {
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .bookpujasec {
    max-width: 335px;
  }
}

@media screen and (max-width: 479px) {
  .slick-initialized .slick-slide {
    padding-right: 0;
  }
  .liveEventcards {
    margin-left: 0 !important;
  }
  .summary-underline-img {
    width: 300px;
  }
  .remedial-issues-container {
    flex-direction: column;
    padding: 20px;
    padding-top: 0;
    margin-top: 200px;
  }
  .remedial-issues-left img {
    width: 200px;
    height: 200px;
  }
  .remedial-issues-left {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .remedial-issues-right {
    padding: 0;
  }
  .contact-us-button {
    padding: 0 10px;
    width: 100%;
    height: 42px;
  }
  .fifthcontsec p {
    background-size: 40px auto;
    font-size: 20px;
    line-height: 30px;
    padding: 40px 0 0 0;
    margin-bottom: 50px;
  }
  .remedial-issues-right h3 {
    font-size: 34px;
    line-height: 38px;
  }
  .frthbtmcontsec h4 {
    font-size: 34px !important;
    line-height: 36px !important;
  }
  .fstcontsec p {
    // font-size: 16px !important;
    line-height: 24px !important;
    padding: 0px 0 0 0 !important;
  }
  .videosec video {
    width: 100%;
    border: 1px solid #df7900;
    height: auto !important;
  }
  .bookpujasec {
    width: 300px;
  }
  .list {
    img {
      max-width: 100%;
      height: auto;
    }
  }
}
@media screen and (max-width: 2560px) and (min-width: 1921px) {
  .slick-slide img {
    width: 100%;
  }
  .home-page-sections {
    max-width: 1700px;
    margin: 0 auto;
    margin-top: 70px;
    font-weight: 600 !important;
  }
  .home-headings {
    font-weight: 600;
  }
  .puja-image {
    height: 250px;
  }
  .puja-image {
    height: 275px;
  }
  .first-sec-img {
    margin-top: 200px !important;
  }
  .remedial-issues-container {
    margin: 100px auto;
    max-width: 1700px;
  }
  .occasion-container {
    max-width: 1700px;
    margin: 100px auto 50px;
    padding: 0;
  }
  .How-its-work-container-mblsec {
    max-width: 1700px;
    margin: 100px auto 0;
    padding: 0;
  }
  .how-it-works-sec-card {
    max-width: unset;
    min-height: 400px;
  }
  .hiw-arrow {
    width: 200px;
  }
  .frthbtmcontsec .container {
    max-width: 2000px;
  }
  .videosec {
    max-width: 1700px;
    height: 800px;
  }
  .fifthcontsec .container {
    max-width: 1700px;
  }
  .see-more-pujas {
    padding: 0;
    margin: 30px auto 0;
    max-width: 1700px;
  }
  .list {
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .life-based-pujas-slider-container, .occasion-based-pujas-slider-container {
    max-width: 1700px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 1920px) and (min-width: 1600px) {
  .home-page-slider-container {
    padding: 0 125px;
  }
  .home-page-sections {
    margin: 0 120px;
  }
  .puja-image {
    width: 100%;
    height: 270px;
  }
  .not-found-container {
    margin: 50px 80px 150px;
  }
  .how-it-works-sec-card {
    max-width: 580px;
  }
  .remedial-issues-container {
    margin: 100px 200px;
  }
  .fifthcontsec .container {
    max-width: 1440px;
  }
  .see-more-pujas {
    padding: 0 200px;
  }
  .tree-img {
    margin-left: 200px;
  }
  .not-found-container {
    margin: 50px 80px 150px;
  }
  .list {
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .insight-slider-container {
    max-width: 1440px;
  }
  .life-based-pujas-slider-container,
  .occasion-based-pujas-slider-container {
    max-width: 1440px;
  }
  .home-page-slider-container {
    padding: 0 125px;
  }
  .life-based-pujas-slider-container,
  .occasion-based-pujas-slider-container {
    margin-left: auto;
  }
}

@media screen and (max-width: 1199px) {
  .occasion-based-pujas-slider-container {
		max-width: 900px;
	}
  .home-page-sections {
    margin: 0px 60px 0px;
  }
  .not-found-container {
    max-width: 565px;
  }
  .fifthcontsec {
    padding: 20px 60px 40px;
    margin: 40px 0 0;
  }
  .list {
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .life-based-pujas-slider-container,
  .occasion-based-pujas-slider-container {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}
@media screen and (max-width: 1024px) {
  .slick-initialized .slick-slide {
    padding-right: 20px;
  }
  .insight-slider-container .slick-arrow.slick-prev {
    left: -42px;
  }
  .insight-slider-container .slick-arrow.slick-next {
    right: -26px;
  }
  .slick-arrow.slick-prev {
    left: -67px;
  }
  .slick-arrow.slick-next {
    right: -33px;
  }
  .not-found-container {
    margin: 50px 60px 0;
  }
  .not-found-text p {
    padding-left: 23px;
  }
  .All__pujas-container .not-found-container {
    max-width: 660px;
  }
  .fifthcontsec {
    margin-top: 30px;
  }
  .remedial-issues-container {
    margin: 80px 60px;
  }
  .remedial-issues-left img {
    width: 500px;
  }
  .bookpujasec {
    height: 320px;
  }
  .list {
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .puja-image {
    width: 100%;
    height: 160px;
  }
  .fstcontsec {
    height: auto;
    padding: 50px 0 0px;
    background-repeat: repeat;
  }
  .insight-slider-container,
  .life-based-pujas-slider-container,
  .occasion-based-pujas-slider-container {
    max-width: 854px;
  }
}
@media screen and (max-width: 991px) {
  .home-page-slider-container-main .slick-dots {
    bottom: -140px;
  }
  .home-page-sections {
    margin: 0 40px;
  }
  .not-found-container {
    display: none;
    max-width: 645px;
  }
  .not-found-container {
    margin: 50px 60px 0;
  }
  .All__pujas-container .not-found-container {
    max-width: 700px;
  }
  .puja-image {
    width: 240px;
    height: 120px;
  }
  .how-it-works {
    background-position-x: -100px;
  }
  .fstcontsec p {
    font-size: 18px;
    line-height: 27px;
    max-width: 100%;
  }
  .fstcontsec {
    background-size: auto 100%, 100% auto;
    margin-bottom: 0;
  }
  .occasion-container {
    padding: 0 40px;
    margin-top: 60px;
  }
  .occasion-based-puja-status {
    margin-top: 60px;
  }
  .How-its-work-container-mblsec {
    padding: 0 40px;
    margin-top: 60px;
  }
  .how-it-works-container {
    margin: 20px 0;
  }
  .how-it-works-sec-card {
    padding: 20px;
  }
  .how-it-works-sec-card img {
    height: 140px;
    width: 140px;
  }
  .pujasec {
    margin-right: 60px;
  }
  .remedial-issues-container {
    margin: 100px 40px 80px;
    padding-right: 30px;
  }
  .remedial-issues-left {
    width: 100%;
  }
  .remedial-issues-right {
    font-size: 32px;
  }
  .list {
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .insight-slider-container,
  .life-based-pujas-slider-container,
  .occasion-based-pujas-slider-container {
    max-width: 650px;
  }
  .life-based-pujas-slider-container,
  .occasion-based-pujas-slider-container {
    margin: 0 40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .slick-arrow.slick-prev {
    left: -58px;
  }
  .slick-arrow.slick-next {
    right: -38px;
  }
  .book-puja-container .pujaseckmlabel {
    max-width: 150px;
  }
  .not-found-container {
    margin: 50px 60px 0;
    max-width: 565px;
  }
  .liveEventcards {
    width: 650px !important;
  }
  .fifthcontsec p {
    font-size: 20px;
    background-size: 30px auto;
    padding: 20px;
  }
  .fifthcontsec img {
    width: 100%;
    margin-top: 90px;
  }
  .remedial-issues-container p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 29px;
    letter-spacing: 0.01em;
    color: #262525;
  }
  .remedial-issues-right h3 {
    font-family: "Playfair Display",sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 32px;
    color: #5a1740;
  }
}
@media screen and (max-width:790px) {
  .occation-based-puja-mblsec {
    display: grid;
    grid-template: auto / repeat(2, 1fr);
}
    .occasion-container {
    display: none;
  }
  .occation-based-event-mblsec {
    display: block;
    margin-top: 50px !important;
  }
  .puja-img-sec {
    display: flex;
    justify-content: center;
    align-items: center;
}
.puja-title-mblsec {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #262525;
  text-align: center;
  margin-top: 10px;
  font-family: "Quicksand",sans-serif;
}
}
@media screen and (max-width: 768px) {
  .bookPoojaSection {
    background-image: none !important;
  }
  .contact-us-button {
    padding: 10px 27px;
  }
  .frthbtmcontsec h4 {
    padding-bottom: 25px;
  }
  .fstcontsec .pujaseckmlabel {
    max-width: 118px;
  }
  .fifthcontsec p {
    background-size: 45px auto;
    padding: 50px 0 0 0;
    margin-bottom: 20px;
  }
  .how-it-works-container-mblsec {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px 0px;
  }
  .how-it-works-sec-card-mblsec {
    margin-right: 0px;
    padding: 0px 23px 30px;
    margin-bottom: 20px;
  }
  .occasion-based-puja-header {
    margin-bottom: 0px !important;
  }
  .occation-based-event-mblsec {
    display: block;
    margin-top: 50px !important;
  }
  .occasion-tab {
    margin: 0px !important;
  }
  .fstcontsec p {
    padding: 20px 0 0 0;
  }
  .hiw-arrow {
    transform: rotate(90deg);
    position: relative;
    top: -10px;
  }
  .bookpujasec {
    width: 500px;
  }
  .know-more-border {
    padding-right: 10px;
  }
  .pujasec-behalf .hover-content a {
    width: 76%;
    height: 76px;
    top: 12.6%;
    left: 0;
  }
  .remedial-issues-container {
    flex-direction: column;
    padding: 20px;
  }
  .remedial-issues-left {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .remedial-issues-left img {
    height: 300px;
    width: 300px;
  }
  .remedial-issues-right .contact-us-button {
    align-self: center;
  }
  .not-found-container {
    margin: 50px 30px 0;
    max-width: unset;
    width: unset;
    padding: 0 0 10px;
  }
  .fstcontsec {
    background-position: right top;
    background-size: 360px auto;
    padding: 130px 0 0;
    border: 1px solid #cfcfcf;
    border-bottom: 0;
    box-shadow: none;
  }
  .fstcontsec h1 {
    font-size: 38px;
    line-height: 43px;
    padding: 0;
    max-width: 198px;
  }
  .fstcontsec a {
    display: inline-block;
    width: 120px;
    height: 40px;
    background: #5a1740;
    color: #fff;
    font-family: "Quicksand", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    border: 0;
    outline: 0;
    line-height: 40px;
    text-align: center;
  }
  .fstcontsec p {
    padding: 20px 0 0 0;
  }
  .fstcontsec p:last-child {
    max-width: 305px;
    background-size: 30px auto;
    padding-left: 0;
    padding-top: 0px;
  }
  .life-based-pujas-slider-container,
  .occasion-based-pujas-slider-container {
    padding-left: 15px;
  }
}
@media screen and (max-width: 375px) {
  .lamp-img img {
    margin-top: 20px;
  }
  .slick-arrow.slick-prev  {
		left: -62px;
	}
}
@media screen and (max-width: 479px) {
  .slick-initialized .slick-slide {
    padding-right: 0;
  }
  .home-page-sections {
    margin: 0;
  }
  .lamp-img {
    position: absolute;
    top: 19px;
    left: 150px;
  }
  .bookpujasec {
    max-width: 320px;
  }
  .fifthcontsec p {
    background-size: 40px auto;
    font-size: 20px;
    line-height: 30px;
    padding: 40px 0 0 0;
    margin-bottom: 50px;
  }
  .see-more-pujas {
    padding: 0 20px;
    justify-content: space-between;
  }
  .see-more-pujas p,
  .view-all-pujas a {
    font-size: 14px;
  }
  .How-its-work-container-mblsec {
    padding: 0 20px;
  }
  .remedial,
  .wellbeing,
  .winover,
  .wishfulfill {
    display: none;
  }
  .not-found-container {
    padding: 0;
    padding-bottom: 10px;
    margin: 80px 20px 0;
    max-width: unset;
    width: unset;
  }
  .contact-us-button {
    padding: 0 10px;
    width: 100%;
    height: 42px;
  }
  .not-found-contact .contact-us-button {
    width: 120px;
  }
  .remedial-issues-container {
    flex-direction: column;
    padding: 20px;
    padding-top: 0;
  }
  .remedial-issues-left {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .remedial-issues-left img {
    width: 200px;
    height: 200px;
  }
  .remedial-issues-right {
    padding: 0;
  }
  .remedial-issues-right h3 {
    font-size: 34px;
    line-height: 38px;
  }
  .bookpujasec-header {
    font-size: 14px;
    margin-top: 10px;
  }
  .pujamodelabel {
    font-size: 14px;
  }
  .pujamodesec {
    flex-wrap: wrap;
  }
  .pujasec {
    width: 190px;
    margin-right: -24px;
  }
  .pujasec-label {
    font-size: 14px;
  }
  .pujasec-mbl {
    width: max-content !important;
    margin-top: 25px;
  }
  .insight-slider-container,
  .life-based-pujas-slider-container,
  .occasion-based-pujas-slider-container {
    max-width: 300px;
  }
  .life-based-mobile-slider-tabs {
    display: none;
  }
  .life-based-pujas-slider-container {
    margin: 0 40px;
  }
  .occasion-based-pujas-slider-container {
    margin: 0 40px;
  }
  .home-page-slider-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 768px) and (min-width:600px) {
  .life-based-card {
    margin-right: 0px !important;
}
}
@media screen and (max-width: 400px) {
  .view-all-pujas {
    padding: 1px;
  }
  .remedial-issues-container {
    padding: 18px;
  }
  .insight-slider-container {
    max-width: 250px;
  }
}
@media screen and (max-width: 375px) {
  .life-based-pujas-slider-container,
  .occasion-based-pujas-slider-container {
    max-width: 280px;
  }
  .lamp-img img {
    margin-top: 20px;
  }
  .home-page-slider-container-viewmore {
    width: 275px;
  }
}
@media screen and (max-width:790px) {
  .slick-arrow.slick-prev {
    left: -58px;
  }
  .occasion-container{
    display:none
  }
  .occEvent{
    display:none
  }
  .occation-based-event-mblsec {
    display: block !important;
    margin-top: 50px !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1030px) {
  .lamp-img img {
    height: 440px;
    width: 100%;
    position: relative;
    right: 50px;
    margin-top: 70px;
  }
}
@media screen and (max-width: 360px) {
  .book-puja-container .pujaseckmlabel {
  line-height: 0px;
  }
  .fifthcontsec img {
    margin-top: 0px;
}
  a.viewallbut {
    font-size: 14px;
  }
  .occasion-container{
    display:none
  }
  .puja-card-mblsec{
  max-width: 120px !important;
  margin: 20px;
  }
  .book-puja-container .pujaseckmlabel {
    max-width: 150px;
  }
  .book-puja-container .bookpujasec {
    height: 500px;
  }
  .pujasec-behalf{
    margin-left: 10px;
  }
  .bookpujasec {
    width: 350px !important;
}
}
@media screen and (max-width: 1600px) {
  .bookpujasec {
    display: block;
  }
  .fstcontsec p {
    font-size: 20px;
    line-height: 32px;
    max-width: 360px;
  }
}
@media screen and (max-width: 1280px) {
  .pujamodelabel {
    padding-top: 12px;
  }
  .life-event-home-headings {
    margin-top: 30px !important;
  }
}

@media screen and (max-width: 1280px) and (min-width: 991px) {
  .list {
    width: 300px !important;
  }
  .fifthcontsec p {
    font-size: 30px;
    line-height: 50px;
    background-size: 60px auto;
    padding-left: 80px;
  }
  .fifthcontsec img {
    width: 100%;
    margin-top: 30px;
  }
}

@media screen and (max-width: 1199px) {
  .pujasec {
    margin-right: 20px;
  }
  .bookpujasec {
    max-width: 565px !important;
    padding: 22px 28px !important;
  }
  .fstcontsec {
    background-size: auto 70%, 100% auto;
  }
}
@media screen and (max-width: 1025px) {
  .slick-initialized .slick-slide {
		padding: 15px 20px 20px 2px;
	}
  .home-page-sections {
    margin: 0 50px;
  }
  .pujasec {
    margin-right: 20px;
  }
}
@media screen and (max-width: 790px) {
  .pujasec-mbl {
    width: max-content !important;
    margin-top: 25px;
  }
  .occation-based-puja-mblsec {
    display: grid !important;
    grid-template: auto / repeat(2, 1fr);
  }
  .puja-card-mblsec {
    background: #ffffff;
    border: 1px solid #cfcfcf;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
    padding: 21px 12px;
    width: 100%;
    max-width: 150px;
    margin-bottom: 20px;
    max-height: 134px;
    justify-self: center;
  }
  .puja-title-mblsec {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #262525;
    text-align: center;
    margin-top: 10px;
    font-family: "Quicksand",sans-serif !important;
  }
}
@media screen and (max-width: 768px) and (min-width: 480px) {
  .liveEventcards {
    width: 422px !important;
  }
  .bookpujasec {
    max-width: 550px !important;
    margin-top: 30px;   //added margin to mkae UI look better
  }
  .SectionTopBookPooja h1 {
    margin-top: 90px;
  }
  .our-booking-url {
    display: none;
  }
  .liveEvent h1 {
    font-size: 34px;
    margin-top: 110px;
  }
  .liveEvent .cardImage {
    max-width: 100%;
    height: 160px !important;
    border: 0.5px solid #5a1740;
  }
  .remedial-issues-container p {
    font-style: normal;
    font-size: 13px;
  }
  .occation-based-event-mblsec {
    display: block !important;
    margin-top: 50px !important;
  }
  .occation-based-puja-mblsec {
    display: grid !important;
    grid-template: auto / repeat(2, 1fr);
  }
  .puja-card-mblsec {
    background: #ffffff;
    border: 1px solid #cfcfcf;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
    padding: 21px 12px;
    width: 100%;
    max-width: 150px;
    margin-bottom: 20px;
    max-height: 134px;
    justify-self: center;
  }
  .puja-img-sec {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .puja-title-mblsec {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #262525;
    text-align: center;
    margin-top: 10px;
    font-family: "Quicksand",sans-serif !important;
  }
  .liveEvent h1 {
    font-size: 40px;
  }
  .see-more-pujas .textpooja {
    font-size: 20px !important;
  }
  .occEvent {
    display: none !important;
  }
  .insight-slider-container,
  .life-based-pujas-slider-container,
  .occasion-based-pujas-slider-container {
    max-width: 380px;
  }
  .insight-slider-container {
    padding-left: 20px;
  }
}
@media screen and (max-width: 480px) and (min-width: 350px) {
  .liveEventcards {
    width: 380px !important;
  }
  .bookpujasec {
    max-width: 390px !important;
  }
  .book-puja-container .pujasec-label {
    line-height: 20px;
  }
  .fstcontsec {
    background-size: 240px auto;
    padding: 95px 0 0;
    background-position: right 42px;
    overflow: unset;
    display: unset;
  }
  .fstcontsec h1 {
    font-size: 34px !important;
    line-height: 42px !important;
    margin-top: 20px;
  }
  .fstcontsec p {
    // font-size: 16px;
    line-height: 24px;
    padding: 0px 0 0 0;
  }
  .fstcontsec p:last-child {
    margin-top: 0px;
  }
}
@media screen and (max-width:344px) {
  .lamp-img img {
    display: none;
  }
}
@media screen and (max-width: 415px) {
  .liveEventcards {
    width: 300px !important;
  }
  .SectionTopBookPooja h1 {
    font-size: 32px !important;
  }
  .bookpujasec {
    width: 335px !important;
    height: 550px !important;
  }
  .pujasec {
    margin-top: 10px;
  }
  .liveEvent h1 {
    line-height: 45px;
    font-size: 30px;
  }
  .list {
    margin-left: 7px;
  }
  .home-page-viewmore {
    margin-top: 0px;
  }
  .occation-based-event-mblsec {
    display: block !important;
    margin-top: 50px !important;
  }
  .puja-card-mblsec {
    background: #ffffff;
    border: 1px solid #cfcfcf;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
    padding: 21px 12px;
    width: 100%;
    max-width: 120px;
    margin-bottom: 20px;
    max-height: 124px;
    justify-self: center;
  }
  .puja-img-sec {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .puja-title-mblsec {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #262525;
    text-align: center;
    margin-top: 10px;
    font-family: "Quicksand",sans-serif !important;
  }
  .occEvent {
    display: none !important;
  }
  .puja-image {
    width: 260px ;
    height: 150px !important;
  }
}
@media screen and (max-width: 1440px) and (min-width: 1367px) {
  .not-found-container {
    margin: 50px 80px;
  }
  .fstcontsec h1 {
    margin-top: 50px;
  }
}
@media screen and (max-width: 1280px) {
  .fstcontsec {
    padding-bottom: 0;
  }
}
@media screen and (max-width: 900px) {
  .fstcontsec h1 {
    font-size: 50px;
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 767px) and (min-width: 480px) {
  .mybookings-status {
    padding-left: 30px !important;
  }
  .life-event-puja-status {
    margin-top: 50px;
  }
  .puja-image {
    width: 100%;
    height: 200px;
  }
  .not-found-text p {
    font-size: 16px;
    padding: 0;
  }
.fstcontsec {
  display: flex;
  flex-direction: column-reverse;
}
.fstcontsec h1 {
  max-width: 300px;
  margin-bottom: 60px;
}
.first-sec-img .not-found-container .not-found-contact .contact-us-button {
  padding: 0;
}
.not-found-container {
  margin: 50px 30px 0;
  max-width: unset;
  width: unset;
  padding: 0 0 10px;
}
.first-sec-img .not-found-container .not-found-contact .contact-us-button {
  padding: 0;
}
.lamp-img img {
  height: 450px;
  width: 350px;
  position: absolute;
  right: 0;
  top: 35px;
}
.insight-slider-container,
.life-based-pujas-slider-container,
.occasion-based-pujas-slider-container {
  max-width: 600px;
}
}
@media screen and (max-width: 720px) and (min-width: 600px) {
  .insight-slider-container .slick-arrow.slick-prev {
    left: -62px;
  }
  .insight-slider-container,
  .life-based-pujas-slider-container,
  .occasion-based-pujas-slider-container {
    max-width: 450px;
  }
}
@media screen and (min-width: 880px) and (max-width: 992px) {
  .first-sec-img {
    margin-top: 0 !important;
  }
  .not-found-container {
    margin: 30px 60px 0;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .lamp-img img {
    height: 400px;
  }
}
@media screen and (max-width: 825px) and (min-width: 768px) {
  .life-event-home-headings {
    margin-top: 350px !important;
}
  .lamp-img img {
    height: 400px;
  }
}
@media screen and (max-width: 500px) {
  .home-page-sections {
      margin: 0 30px;
  }
}
@media screen and (max-width: 409px) {
  .not-found-contact .contact-us-button {
    width: 120px;
  }
  .book-puja-container {
      margin-top: 90px !important;
  }
  .not-found-container {
    padding: 0;
    padding-bottom: 10px;
    margin: 30px 20px 0;
    max-width: unset;
    width: unset;
  }
  .All__pujas-container .not-found-container {
    margin: 60px 0 0;
  }
  .All__pujas-container .not-found-container .not-found-contact {
    margin-left: auto;
    margin-top: 20px;
  }
}
@media screen and (max-width: 767px) {
  .slick-dotted.slick-slider {
    margin-bottom: 0px;
  }
  .slick-arrow.slick-prev {
		left: -3rem;
		transform: translate(100%, -50%);
	}
  .home-page-slider-container-viewmore {
    width: 300px;
    height: 40px;
    margin-right: auto;
    margin-left: auto;
    padding: unset;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .life-event-home-headings {
    margin-top: 80px !important ;
    line-height:38px;
  }
  .my-bookings-wrapper .mybookings-status h2 {
    font-size: 32px;
}
.mybookings-status-wrapper {
    display: block;
}
.mybookings-status-wrapper .mybookings-satus-box {
    width: 100%;
    display: flex;
    align-items: center;
	margin-bottom: 25px;
}
.mybookings-status-wrapper .mybooking-satus-icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 15px 0px 0px;
}
.mybookings-status-wrapper .mybookings-satus-box:last-child:after {
    left: 25px;
	display: none;
}
.mybookings-status-wrapper .mybookings-satus-box:first-child:after {
    left: 25px;
}
.mybookings-status-wrapper .mybookings-satus-box::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    background: #f2c999;
    top: 25px;
    left: 25px;
    transform: translate(0%, 0%);
    z-index: -1;
}
.mybookings-status-wrapper .mybookings-satus-box p {
    height: fit-content;
    text-align: left;
    width: 75%;
    margin-bottom: 0;
}
.my-bookings-wrapper .mybookings-status-wrapper .mybookings-satus-box {
  align-items: center;
}
.mybookings-status {
  padding: 0 20px 15px;
}
.mybookings-status h2 {
  padding: 1rem 0rem 0;
}
.mybookings-status.border-bottom {
  padding: 0;
}
  .puja-book-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .home-page-slider-container-viewmore {
    width: 300px;
    height: 40px;
    margin-right: auto;
    margin-left: auto;
    padding: unset;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .life-based-pujas-slider-container,
  .occasion-based-pujas-slider-container {
    padding-left: 0;
  }
  .life-based-pujas-slider-container,
  .occasion-based-pujas-slider-container {
    padding-left: 0;
  }
  .puja-title-mblsec {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #262525;
    text-align: center;
    margin-top: 10px;
    font-family: "Quicksand",sans-serif;
  }
}
@media screen and (max-width: 767px) {
  .life-event-home-headings {
    margin-top: 70px !important;
  }
  .life-based-pujas-slider-container,
  .occasion-based-pujas-slider-container {
    padding-left: 15px;
  }
}
@media screen and (max-width: 480px){
  .home-page-slider-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mybookings-status-wrapper .mybooking-satus-icon-wrap img {
		width: 25px;
		height: 25px;
	}
}
@media screen and (max-width: 2560px) and (min-width: 1921px) {
  .life-based-pujas-slider-container, .occasion-based-pujas-slider-container {
    max-width: 1700px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 1920px) and (min-width: 1600px) {
  .life-event-based-puja-websec {
    margin-top: 100px;
  }
  .home-page-slider-container {
    padding: 0 125px;
  }
  .life-based-pujas-slider-container,
  .occasion-based-pujas-slider-container {
    max-width: 1440px;
  }
  .life-based-pujas-slider-container,
  .occasion-based-pujas-slider-container {
    margin-left: auto;
  }
}
@media screen and (max-width: 1199px) {
  .life-based-pujas-slider-container,
  .occasion-based-pujas-slider-container {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}
@media screen and (max-width: 991px) {
  .home-headings {
    font-size: 40px;
  }
  .insight-slider-container,
  .life-based-pujas-slider-container,
  .occasion-based-pujas-slider-container {
    max-width: 650px;
  }
  .life-based-pujas-slider-container,
  .occasion-based-pujas-slider-container {
    margin: 0 40px;
  }
  .puja-book-button {
    width: 110px;
  }
}
@media screen and (max-width: 479px) {
  .home-page-slider-container-main .slick-dots {
    bottom: -195px;
  }
  .insight-slider-container .slick-arrow.slick-prev {
    left: -65px;
  }
  .home-page-slider-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .home-headings {
    font-size: 32px;
  }
  .life-event-puja-status {
    margin-top: 80px;
  }
  .life-based-pujas-slider-container {
    margin: 0 40px;
  }
  .life-based-card {
    margin-right: 0px;
  }
  .puja-image {
    width: 290px;
    height: 180px;
  }
  .mySlides .bottom-items .puja-book-button {
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 991px) and (min-width: 881px) {
	.life-event-home-headings {
		margin-top: 250px !important;
	}
}
@media screen and (max-width: 1600px) {

  .life-event-home-headings {
  margin-top: 70px;
  font-weight: 600 !important;
}
}
@media screen and (max-width: 991px) and (min-width: 825px) {
	.life-event-home-headings {
		margin-top: 370px !important;
    font-size: 50px;
    margin-left: 0px;
    font-weight: 600;
	}
  .home-page-sections {
    margin: 0px !important;
  }
}
@media screen and (max-width: 1024px) {
  .slick-initialized .slick-slide {
    padding-right: 20px;
  }
  .insight-slider-container .slick-arrow.slick-prev {
    left: -42px;
  }
  .slick-arrow.slick-prev {
    left: -67px;
  }
  .life-event-home-headings {
		margin-top: 70px ;
	}
  .insight-slider-container,
  .life-based-pujas-slider-container,
  .occasion-based-pujas-slider-container {
    max-width: 854px;
  }
  .bottom-price-btn .puja-price {
    font-size: 20px;
  }
  .puja-book-button {
    width: 120px;
  }
}
@media screen and (max-width: 375px) {
  .occasion-based-card {
    box-shadow: none;
  }
  .life-based-pujas-slider-container,
  .occasion-based-pujas-slider-container {
    max-width: 280px;
  }
  .home-page-slider-container-viewmore {
    width: 275px;
  }
}