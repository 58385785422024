.karishye-thank-form {
    padding: 150px 0 100px;
    h1 {
      text-align: center;
      font-family: "Playfair Display", serif;
      font-weight: 600;
    }
    p {
      text-align: center;
      font-family: "Playfair Display", serif;
      font-size: 16px;
      font-weight: 600;
      font-style: normal;
    }
  }
  .congratulation-img {
    text-align: center;
    margin-bottom: 2%;
  }
  .congratulation-img img {
    width: 26%;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  .inner-wrapper p {
    margin: 2%;
  }
  .continue-btn {
    justify-content: center;
    display: flex;
    margin-top: 2%;
  }
  .bookbut {
    background: #5a1740;
    line-height: 50px;
    color: #fff;
    font-weight: 600;
    font-family: "Quicksand", sans-serif;
    display: block;
    float: left;
    padding: 0 20px;
    border: 1px solid #5a1740;
  }
  @media screen and (max-width: 768px) {
    .karishye-thank-form {
      h1 {
        font-family: "Playfair Display", serif !important;
        font-weight: 500;
      }
      p {
        font-family: "Playfair Display", serif !important;
        font-weight: 500;
      }
    }
    .bookbut{         //fixed button alignment issue
      line-height: 20px;
      height: 40px;
      padding: 10px 20px;
    }
  }
  