.refundSection {
  max-width: 1440px;
  margin: auto;
}

.refundfstsec {
  width: 100%;
  overflow: hidden;
  height: 100vh;
  background-image: url("../../../public/assets/images/about-us-top.png");
  background-repeat: no-repeat, no-repeat;
  background-position: right center, left top;
  background-size: 50% auto, 100% 100%;
}

.refundfstsec h1 {
  font-family: "Fraternite" !important;
  color: #5a1740;
  font-weight: 400;
  font-style: normal;
  font-size: 65px;
  line-height: 86px;
  padding: 0 0 15px 0;
  max-width: 650px;
}

.refundsndsec {
  width: 100%;
  overflow: hidden;
  padding: 50px 0 80px 0;
  font-family: "Playfair Display", serif !important;
}

.refundsndsec u{
  font-family: "Playfair Display", serif !important;
}

.refundsndsec p {
  margin-bottom: 20px !important;
  font-family: "Playfair Display", serif !important;
  font-size: 1.05rem;
  line-height: 1.6;
  color: #262525;
  strong{
    font-family: "Playfair Display", serif !important;
  }
}

.refundSection .herosection-text {
  position: relative;
  top: 40%;
  transform: translateY(-50%);
}

.refundSection .herosection-text {
  position: relative;
  top: 40%;
  transform: translateY(-50%);
}

@media screen and (max-width: 767px) {
  .refundfstsec {
    background-position: center 20px, left top;
    background-size: 100% auto, 100% 100%;
    padding-top: 90%;
    padding-bottom: 60px;
  }
  .refundfstsec h1 {
    max-width: 100%;
    font-size: 34px !important;
    line-height: 38px !important;
  }
}

@media screen and (max-width: 991px) {
  .refundfstsec h1 {
    font-size: 45px;
    line-height: 50px;
  }
  .refundfstsec {
    height: 60vh !important;
  }
  .refundfstsec .herosection-text {
    top: 40%;
  }
}

@media screen and (max-width:420px) {
  .refundfstsec {
    height: 80vh !important; // Change the height of the section for mobile
  } 
}

@media screen and (max-width: 1600px) {
  .refundfstsec h1 {
    font-size: 65px;
    line-height: 65px;
  }
  .refundfstsec {
    height: 80vh;
  }
}