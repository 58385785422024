.priestpgfstcontsec {
  width: 100%;
  overflow: hidden;
  height: 100vh;
  background-image: url("../../../public/assets/images/priestpgbg01.png");
  background-position: right center, left top;
  background-size: 50% auto, 100% 100%;
  background-repeat: no-repeat;
}
.herosection-text {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.priestpgfstcontsec h1 {
  font-family: "Fraternite", serif !important;
  color: #5a1740;
  font-style: normal;
  font-size: 65px;
  line-height: 70px;
  padding: 0 0 15px 0;
  max-width: 400px;
}
.priestpgfstcontsec p {
  color: #262525;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 32px;
  max-width: 550px;
  font-family: "Playfair Display", serif !important;
}
.priestbottomsection {
  display: flex;
  justify-content: center;
}
.priestpgsndcontsec {
  width: 100%;
  overflow: hidden;
  background-image: url("../../../public/assets/images/priestpgbg02.png");
  background-position: center center, left top;
  background-size: auto auto, 100% 100%;
  padding: 0 0 100px 0;
  background-repeat: no-repeat;
}

.priestpgsndcontsec h2 {
  text-align: center;
  font-family: "Fraternite";
  color: #5a1740;
  font-weight: normal;
  font-style: normal;
  font-size: 65px;
  line-height: 65px;
  padding: 0 0 25px 0;
}

.priestpgsndcontsec p {
  line-height: 32px;
  font-family: "Playfair Display", serif !important;
}
.priestpgthrdcontsec {
  margin-top: 2rem;
}

.midpriestheight {
  height: 550px;
}

.priestpgthrdcontsec h2 {
  font-family: "Fraternite" !important;
  color: #5a1740;
  font-weight: normal;
  font-style: normal;
  font-size: 65px;
  line-height: 65px;
  padding: 0 0 20px 0;
}
.mobview {
  display: none;
}
.desktopview img {
  max-width: 100%;
  height: auto;
}

.priestpgthrdcontsec ul li {
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 1px;
  margin-top: 20px;
  color: #262525;
  background: url("../../../public/assets/images/verline.png") no-repeat left
    5px;
  padding-left: 30px;
  font-family: "Playfair Display", serif !important;
}

.priestpgfrthcontsec {
  width: 100%;
  overflow: hidden;
  background: url("../../../public/assets/images/flowers.png") no-repeat center
    250px;
  background-size: 50% auto;
  padding: 50px 0 20px 0;
  margin-top: 2rem;
}

.priestpgfrthcontsec h2 {
  text-align: center;
  font-family: "Fraternite" !important;
  color: #5a1740;
  font-weight: normal;
  font-style: normal;
  font-size: 65px;
  line-height: 65px;
  padding: 0 0 10px 0;
  max-width: 750px;
  margin: 0 auto;
}

.priestpgfrthcontsec p {
  font-family: "Playfair Display", serif !important;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  max-width: 750px;
  margin: 0 auto;
}

.priestboxsec {
  width: 100%;
  overflow: hidden;
  margin-top: 300px;
}
.priestpgfifthcontsec {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.sglepriestboxsec {
  border: 1px solid #cfcfcf;
  padding: 25px 30px;
  background: #fff;
  height: auto;
  margin-bottom: 1.6rem;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.08);
}

.sglepriestboxsec p:last-child {
  background: url("../../../public/assets/images/horline.png") no-repeat left
    bottom;
  padding-bottom: 30px;
}

.sglepriestboxsec p {
  text-align: left;
  font-size: 16px;
  line-height: 32px;
  color: #000000;
  max-width: 100%;
  margin: 0;
}

.priestpgfifthcontsec h2 {
  font-family: "Playfair Display", serif !important;
  text-align: center;
  color: #5a1740;
  font-weight: 500;
  font-style: normal;
  font-size: 30px !important;
  line-height: 45px !important;
  padding: 0;
  max-width: 660px;
  margin: 0 auto;
}

.WhoIsPooja {
  max-width: 1440px;
  margin: auto;
  padding-top: 80px;
}
.hiddenTag {
  display: none;
}
@media screen and (max-width:900px) {
  .priestpgfstcontsec {
    height: 65vh; //adjusted the height to look image properly
  }
}
@media screen and (max-width: 1440px) {
  .priestpgfstcontsec {
    background-position: right top 50px; //changed the background position
  }
  .WhoIsPooja {
    padding-left: 7%;
    padding-right: 7%;
  }
  .priestpgsndcontsec {
    background-position: center 36%, left top;
  }
  .sglepriestboxsec {
    margin-bottom: 10px;
  }
  .WhoIsPooja {
    padding-left: 5%;
    padding-right: 5%;
  }
  .priestpgthrdcontsec h2 {
    font-family: "Fraternite";
    color: #5a1740;
    font-style: normal;
    font-size: 34px;
    line-height: 36px;
    padding: 0 0 20px 0;
  }
  .desktopview img {
    width: 100%;
  }
  .priestboxsec {
    width: 100%;
    overflow: hidden;
    margin-top: 350px;
  }
}

@media screen and (max-width: 767px) {
  .priestpgsndcontsec {
    background-size: 100% auto;
    background-position: center 41%;
    padding-bottom: 60px;
  }
  .priestpgfstcontsec {
    background-position: center 20px, left top;
    background-size: 100% auto, 100% 100%;
    padding-top: 90%;
    padding-bottom: 60px;
    height: 80vh;
  }  //removed the duplicates
  .priestpgfstcontsec h1 {
    margin-top: 190px;
    max-width: 100%;
    font-size: 34px;
    line-height: 38px;
  }
  .midpriestheight {
    height: 400px;
  }
  .priestpgthrdcontsec h2 {
    text-align: center;
  }
  .priestpgfrthcontsec h2 {
    text-align: left;
  }
  .priestpgfrthcontsec {
    background-size: 100% auto;
    background-position: center 180px;
  }
  .priestpgfrthcontsec p {
    text-align: left;
    font-size: 17px;
    line-height: 26px;
  }
  .priestpgfifthcontsec h2 {
    font-size: 20px !important;
    line-height: 32px !important;
  }
}
@media screen and (max-width: 467px) {
  .priestpgfrthcontsec h2 {
    font-size: 34px;
    line-height: 36px;
  }
  .priestpgfstcontsec {
    height: 80vh;
  }
}

@media screen and (max-width: 479px) {
  .midpriestheight {
    height: 225px;
    margin-top: 100px;
  }
  .priestpgsndcontsec {
    background-position: center 42%;
  }
  .priestpgthrdcontsec h2 {
    font-size: 34px;
    line-height: 36px;
  }
  .priestpgfrthcontsec {
    background-position: center 260px;
    background-size: 90% auto !important;
  }
}

@media screen and (max-width: 2560px) and (min-width: 1921px) {
  .priestpgsndcontsec .container,
  .priestpgthrdcontsec .container,
  .priestpgfrthcontsec .container,
  .priestpgfifthcontsec .container {
    max-width: 1700px;
  }
  .priestpgfstcontsec p,
  .priestpgsndcontsec p,
  .priestpgthrdcontsec p,
  .priestpgfrthcontsec p,
  .priestpgfifthcontsec p {
    font-size: 20px;
  }
  .priestpgthrdcontsec ul li {
    font-size: 20px;
  }
  .abtpgsndcontsec .container,
  .abtpgthrdcontsec .container {
    max-width: 1700px;
  }
  .midabtheight {
    margin: 100px 0 200px;
  }
  .contactcontsec {
    padding: 350px 0 400px;
  }
  .contactcontsec .container,
  .contactdatasec .container {
    max-width: 1700px;
  }
  .see-more-pujas {
    padding: 0;
    margin: 30px auto 0;
    max-width: 1700px;
  }
}
@media screen and (max-width: 1600px) {
  .priestpgfstcontsec h1 {
    font-size: 65px;
    line-height: 70px;
    max-width: 400px;
  }
  .priestpgsndcontsec h2,
  .priestpgthrdcontsec h2,
  .priestpgfrthcontsec h2 {
    font-size: 65px;
    line-height: 65px;
  }
  .priestpgfifthcontsec h3 {
    padding-bottom: 10px;
  }
  .priestpgfifthcontsec h2 {
    font-size: 30px;
    line-height: 45px;
  }
}
@media screen and (max-width: 768px) {
  .mobview {
    display: block;
  }
  .priestpgfstcontsec h1 {
    max-width: 100%;
    font-size: 34px;
    line-height: 38px;
  }
  .priestpgsndcontsec h2,
  .priestpgthrdcontsec h2,
  .priestpgfrthcontsec h2 {
    font-size: 40px;
    line-height: normal;
  }
  .priestpgsndcontsec {
    background-size: 100% auto;
    background-position: center 41%;
    padding-bottom: 60px;
  }
  .midpriestheight {
    height: 400px;
  }
  .priestpgthrdcontsec h2 {
    text-align: center;
  }
  .priestpgfrthcontsec {
    background-size: 90% auto;
    background-position: center 180px;
  }
  .priestpgfrthcontsec h2 {
    text-align: center;
  }
  .priestpgfifthcontsec {
    padding-top: 40px;
    padding-bottom: 60px;
  }
  .priestpgfifthcontsec h3 {
    font-size: 16px;
    line-height: 24px;
  }
  .priestpgfifthcontsec h2 {
    font-size: 20px;
    line-height: 32px;
  }
  .priestpgthrdcontsec h3 {
    font-size: 20px;
    line-height: 28px;
  }
  .priestboxsec {
    margin-top: 290px;
  }
}
@media screen and (max-width: 479px) {
  .priestpgsndcontsec h2,
  .priestpgthrdcontsec h2,
  .priestpgfrthcontsec h2 {
    font-size: 34px;
    line-height: 36px;
  }
  .see-more-pujas {
    padding: 0 20px;
    justify-content: space-between;
  }
}

@media screen and (max-width: 360px) {
  .midpriestheight {
    height: 200px;
  }
  .priestpgfrthcontsec {
    background-size: 100% auto;
    background-position: center 180px;
  }
}
