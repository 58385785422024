.policySection {
  max-width: 1440px;
  margin: auto;
}

.ppfstsec {
  width: 100%;
  overflow: hidden;
  height: 100vh;
  background-image: url("../../../public/assets/images/about-us-top.png");
  background-repeat: no-repeat, no-repeat;
  background-position: right center, left top;
  background-size: 50% auto, 100% 100%;
}

.ppfstsec h1 {
  font-family: "Fraternite" !important;
  color: #5a1740;
  font-weight: 400;
  font-style: normal;
  font-size: 80px;
  line-height: 86px;
  padding: 0 0 15px 0;
  max-width: 650px;
}

.ppsndsec {
  width: 100%;
  overflow: hidden;
  padding: 50px 0 80px 0;
}

.ppsndsec p {
  margin-bottom: 20px !important;
  font-size: 1.05rem;
  line-height: 1.6;
  color: #262525;
  font-family: "Playfair Display", serif !important;
  strong{
    font-family: "Playfair Display", serif !important;
  }
  u{
    font-family: "Playfair Display", serif !important;
  }
}

.ppsndsec p a {
  color: #cd2653 !important;
  font-size: 1.05rem;
  font-family: "Playfair Display", serif !important;
}

.ppsndsec p a:hover {
  color: #0d6efd !important;
  font-family: "Playfair Display", serif !important;
}
.ppsndsec ul li {
  font-size: 1.05rem;
  line-height: 1.6;
  list-style-position: inside;
  list-style-type: disc;
  color: #262525;
  font-weight: 500;
  font-family: "Playfair Display", serif !important;
}
@media screen and (max-width:1400px) {
  .ppfstsec {
    height: 80vh;   // adjusted height to look image properly
  }
}
@media screen and (max-width: 767px) {
    .ppfstsec{
        background-position: center 20px, left top;
        background-size: 100% auto, 100% 100%;
        padding-top: 80%;
        padding-bottom: 60px;
    }
    .ppfstsec h1{
        max-width: 100%;
        font-size: 34px !important;
        line-height: 38px !important;
        text-align: center;
    }
    .ppfstsec {
      height: 100vh;
      height: 100vh;
    }
}

@media screen and (max-width: 991px) {
    .ppfstsec h1 {
        font-size: 45px;
        line-height: 50px;
    }
    .ppfstsec {                       // Change the height of the section
      height: 60vh !important;
    }
    .ppfstsec .herosection-text {
      top: 40%;
    }
}

@media screen and (max-width:420px) {
  .ppfstsec {
    height: 80vh !important;  // Change the height of the section for mobile
  } 
}

@media screen and (max-width: 1600px) {
    .ppfstsec h1 {
        font-size: 65px;
        line-height: 65px;
        max-width: 535px;
    }
}